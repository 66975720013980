import React, {useState, useEffect} from 'react'
import useEditForm from '../../forms/useEditForm';
import { baseCalls } from '../../../apiCalls/baseCalls';
import { Box, Typography,Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../genericcomponents/Button'
import Style from '../../../styles/FormStyle'
import {useHistory} from 'react-router-dom'
import useLoading from '../../genericcomponents/useLoading'
import LoadingIndicator from '../../genericcomponents/LoadingIndicator';
import "react-datepicker/dist/react-datepicker.css";
const useStyles = makeStyles(() => (Style));
export default function AdministrationDataForm(props) {
    const history= useHistory()
    
    const classes = useStyles();
    const {donatorData,supporterTypes, statusTypes} = props;
    const {isLoading, setIsLoading} = useLoading(false)

    const submit=(e)=>{
        e.preventDefault()
        setDataUpdated(null)
        setIsLoading(true)
            baseCalls.updateDonatorData(data).then(response=>{
                setIsLoading(false)
                setDataUpdated(response.data.success)
                setFormResponseText(response.data.error)
                if(response.data.success===true){
                    setFormResponseText('Podaci ažurirani!')
                }
                
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
                setDataUpdated(false)
                setFormResponseText('Greška u pozivu')
            })   
    }

    const neededFields= ['businessname','activefrom','firstname','lastname','email','address','addressnumber', 'city','oib','statusname', 'active_from', "paymentdat",'amount']

    const {handleChange,data,
        getNeededFields, labelMapper,
        dataUpdated,setDataUpdated,
        formResponseText,setFormResponseText,
    maxLengthMapper, typeMapper,requiredMapper,
    changeDate, changePaymentDate}= useEditForm(submit,donatorData,neededFields)
    const orderedFields= getNeededFields();

    let activeTemp;
    data.active_from===null? activeTemp=new Date(): activeTemp=new Date(data.active_from);
    activeTemp.setHours(20)
    const [date, setDate] = useState(activeTemp);
    
    let activeTempPayment
    (data.paymentdat===null || data.paymentdat === "0001-01-01T01:00:00 BC") ? activeTempPayment = "" : activeTempPayment = new Date(data.paymentdat);
    activeTempPayment && activeTempPayment.setHours(20) 
    const [paymentDate, setPaymentDate] = useState(activeTempPayment);

    useEffect(() => {
        changeDate(date)
    }, [date])

    useEffect(() => {
        changePaymentDate(paymentDate)
    }, [paymentDate])

    const [inputPaymentDate, setInputPaymentDate ] = useState()
    const [inputDate, setInputDate ] = useState()

    useEffect(() => {        
        if(data.paymentdat !== null && data.paymentdat !== "" && data.paymentdat !== "0001-01-01T01:00:00 BC") {
            activeTempPayment.setDate(activeTempPayment.getDate());
            setInputPaymentDate(activeTempPayment.toISOString().substr(0,10))
        } 
    }, [data.paymentdat])

    useEffect(() => {        
        if(data.active_from !== null && data.active_from !== "") {
            activeTemp.setDate(activeTemp.getDate());
            setInputDate(activeTemp.toISOString().substr(0,10))
        } 
    }, [data.active_from])

    return (
        <>
        <Hidden smDown>
            <form className={classes.form} onSubmit={submit}>
                <Box className={classes.formContainer}>
                    <Box className={classes.box}> 
                        <Typography className={classes.label}>Tip</Typography>
                        <select required={true} name={"supportertype"} defaultValue={donatorData.supportertype} onChange={handleChange} className={classes.formFields}>
                            {
                                supporterTypes.map(type=>(
                                    <option key={type.supporters.id} value={type.supporters.id}>{type.supporters.name}</option>)
                                )
                            }
                        </select>
                    </Box>
                    {
                        Object.keys(orderedFields).map(key=>
                            {       let label= labelMapper(key)
                                    let maxLength= maxLengthMapper(key)
                                    let type=typeMapper(key)
                                    if(key==="statusname"){
                                        return(
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <select  className={classes.formFields} type={type} name={"status"} key={key} defaultValue={donatorData.status} onChange={handleChange}>
                                                    {
                                                        statusTypes.map(type=>
                                                        <option key={type.status.id} value={type.status.id}>
                                                            {type.status.name}
                                                        </option>)
                                                    }
                                                </select>
                                            </Box>
                                        )
                                    }else if(key==="active_from"){
                                        if(data.status==="A"){
                                            return (
                                                <Box key={key} className={classes.box}>
                                                    <Typography className={classes.label}>{label}</Typography>
                                                    <input type="date" defaultValue={inputDate} className="calendar" name={key} key={key} selected={date} onChange={e => (setDate(e.target.value)) }/>
                                                </Box>)
                                        }else{
                                            return(<></>)
                                        }   
                                    } else if(key==="paymentdat") {
                                        return (
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <input type="date" defaultValue={inputPaymentDate} className="calendar" name={key} key={key} selected={paymentDate} onChange={e => (setPaymentDate(e.target.value)) }/>
                                            </Box>
                                        )
                                    } else {
                                        let required= requiredMapper(key)
                                        return( 
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <input required={required} maxLength={maxLength} className={classes.formFields} type={type} name={key} key={key} value={data[key] || ""} onChange={handleChange}/>
                                            </Box>
                                        
                                        )
                                    }
                            }
                        )
                    }
                </Box>
                <Box className={classes.buttonContainer}>
                    <Button action={history.goBack} text={'Povratak'}/>
                    {
                        isLoading ?
                        (<LoadingIndicator/>):
                        (<Button type={"submit"} text={'Spremi promjene'}/>)
                    }
                    {
                        dataUpdated!==null ? 
                            dataUpdated===true ?
                            (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>):
                            (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                        :(<></>)
                    }
                    
                </Box>
            </form>
        </Hidden>
        <Hidden mdUp>
        <form className={classes.formSm}  onSubmit={submit}>
                <Box className={classes.formContainer}>
                    <Box className={classes.box}> 
                        <Typography className={classes.label}>Tip</Typography>
                        <select name={"supportertype"} defaultValue={donatorData.supportertype} onChange={handleChange} className={classes.formFields}>
                            {
                                supporterTypes.map(type=>(
                                    <option key={type.supporters.id} value={type.supporters.id}>{type.supporters.name}</option>)
                                )
                            }
                        </select>
                    </Box>
                    {
                        Object.keys(orderedFields).map(key=>
                            {       let label= labelMapper(key)
                                    let maxLength= maxLengthMapper(key)
                                    let type=typeMapper(key)
                                    if(key==="statusname"){
                                        return(
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <select  className={classes.formFields} type={type} name={"status"} key={key} defaultValue={donatorData.status} onChange={handleChange}>
                                                    {
                                                        statusTypes.map(type=>
                                                        <option key={type.status.id} value={type.status.id}>
                                                            {type.status.name}
                                                        </option>)
                                                    }
                                                </select>
                                            </Box>
                                        )
                                    }else if(key==="active_from"){
                                        if(data.status==="A"){
                                            return (
                                                <Box key={key} className={classes.box}>
                                                    <Typography className={classes.label}>{label}</Typography>
                                                    <input type="date" defaultValue={inputDate} className="calendar" name={key} key={key} selected={date} onChange={e => (setDate(e.target.value)) }/>
                                                </Box>)
                                        }else{
                                            return(<></>)
                                        }       
                                    }
                                    else if(key==="paymentdat") {
                                        return (
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <input type="date" defaultValue={inputPaymentDate} className="calendar" name={key} key={key} selected={paymentDate} onChange={e => (setPaymentDate(e.target.value)) }/>
                                            </Box>
                                        )
                                    } else {
                                        let required= requiredMapper(key)
                                        return( 
                                            <Box key={key} className={classes.box}>
                                                <Typography className={classes.label}>{label}</Typography>
                                                <input required={required} maxLength={maxLength} className={classes.formFields} type={type} name={key} key={key} value={data[key] || ""} onChange={handleChange}/>
                                            </Box>
                                        
                                        )
                                    }
                            }
                        )
                    }
                </Box>
                <Box className={classes.buttonContainer}>
                    {
                        isLoading ?
                        (<LoadingIndicator/>):
                        (<Button type={"submit"} text={'Spremi promjene'}/>)
                    }
                    {
                        dataUpdated!==null ? 
                            dataUpdated===true ?
                            (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>):
                            (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                        :(<></>)
                    }
                    <Button action={history.goBack} text={'Povratak'}/>
                </Box>
            </form>
        </Hidden>
        </>
    )
}
