import React, { useState, useEffect } from 'react'
import { Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { baseCalls } from '../../../apiCalls/baseCalls'
import useLoading from '../../genericcomponents/useLoading'
import { useForm } from 'react-hook-form';
import Style from '../../../styles/FormStyle'
import { Link as RouterLink } from 'react-router-dom'
import Button from '../../genericcomponents/Button'
import LoadingIndicator from '../../genericcomponents/LoadingIndicator';
import useEditForm from '../../forms/useEditForm';
import useAddDonators from './useAddDonators';
import Header from '../../genericcomponents/Header/GenericHeader'
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => (Style));
export default function AddDonator() {
    const classes = useStyles();
    const { isLoading, setIsLoading, spinner } = useLoading(true)
    const [ isDonationTypeSupporter, setIsDonationTypeSupporter ] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { fields, setSupporterTypes, setPaymentTypes, setDonationTypes, setStatusTypes, handleChange, changePaymentDate } = useAddDonators()
    const { dataUpdated, formResponseText, setFormResponseText, setDataUpdated,
        labelMapper, maxLengthMapper, typeMapper } = useEditForm()

    useEffect(() => {
        baseCalls.getSupporterTypes().then(response => {
            setSupporterTypes(response.data.data)
            baseCalls.getPaymentTypes().then(response => {
                setPaymentTypes(response.data.data);
                baseCalls.getDonatorTypes().then(response => {
                    setDonationTypes(response.data.data);
                    baseCalls.getStatusTypes().then(response => {
                        setStatusTypes(response.data.data);
                        setIsLoading(false)
                    })
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])

    const { register, handleSubmit, watch } = useForm();

    const watchDonationType = watch("donationType");
    const watchSupporterType = watch("supporterType", '0');

    useEffect(() => {
        if(watchDonationType === "1") {
            setIsDonationTypeSupporter(true)
        }
        if(watchDonationType === "0" && watchSupporterType !== "0" ) {
            setIsDonationTypeSupporter(false)
        }
        if(watchSupporterType === "0") {
            setIsDonationTypeSupporter(false)
        }
    }, [watchDonationType, watchSupporterType])

    const onSubmit = data => {
        setBtnLoading(true)
        baseCalls.addDonator(data).then(response => {
            setBtnLoading(false)
            setDataUpdated(response.data.success)
            setFormResponseText(response.data.error)
            if (response.data.success === true) {
                setFormResponseText('Donator dodan!')
            } else {
                setDataUpdated(false)
                setFormResponseText('Greška u pozivu')
            }
        }).catch(err => {
            console.log(err)
            setBtnLoading(false)
            setDataUpdated(false)
            setFormResponseText('Greška u pozivu')
        })
    }

    const refreshPage = () => {
        console.log("button clicked")
        window.location.reload();
    }

    const [paymentDate, setPaymentDate] = useState();

    //let paymendDateString = (new Date(paymentDate).toLocaleDateString())
    let paymentDateString
    paymentDate === undefined ? paymentDateString = null : paymentDateString = new Date(paymentDate).toLocaleDateString()

    useEffect(() => {
        changePaymentDate(paymentDateString)
    }, [paymentDate])

    return (
        <>
            <Header text={"Dodavanje donatora"} active={"Dodaj donatora"} />
            <main>
                {
                    isLoading ? (
                        spinner
                        ) : (
                            <>
                                <Hidden smDown>
                                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                                        <Box className={classes.formContainer}>
                                            {fields.map(field => {
                                                let label = labelMapper(field.field)
                                                let maxLength = maxLengthMapper(field.field)
                                                let type = typeMapper(field.field)
                                                if (field.isSelect === true) {
                                                    if (field.conditionalRender === true) {
                                                        if (watchDonationType === '0') {
                                                            return (
                                                                <Box key={field.field} className={classes.box}>
                                                                    <Typography className={classes.label}>{label}</Typography>
                                                                    <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                        {
                                                                            field.options.filter(option => option[field.optionKey].id === -1).map(type => (
                                                                                <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                            )
                                                                        }
                                                                    </select>
                                                                </Box>
                                                            )
                                                        } else if (watchDonationType !== '0' && watchDonationType !== undefined && watchDonationType !== '') {
                                                            return (
                                                                <Box key={field.field} className={classes.box}>
                                                                    <Typography className={classes.label}>{label}</Typography>
                                                                    <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                        {
                                                                            field.options.filter(option => option[field.optionKey].id !== -1).map(type => (
                                                                                <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                            )
                                                                        }
                                                                    </select>
                                                                </Box>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                    <option>

                                                                    </option>
                                                                    {
                                                                        field.options.map(type => (
                                                                            <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                        )
                                                                    }
                                                                </select>
                                                            </Box>
                                                        )
                                                    } 
                                                } else {
                                                    if(field.field==="paymentdat"){
                                                        return (                                        
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <input type="date" className="calendar" ref={register} name={field.field} key={field.field} selected={paymentDate} onChange={e =>{setPaymentDate(e.target.value)}} />
                                                            </Box>) }
                                                    else if ((watchSupporterType !== '0') && isDonationTypeSupporter) {
                                                        return (
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <input ref={register} required={field.requried} maxLength={maxLength} className={classes.formFields} type={type} name={field.field} key={field.field} value={field.value} onChange={handleChange} />
                                                            </Box>
                                                        )
                                                    } else if(watchDonationType === '0' && !isDonationTypeSupporter){
                                                        return (
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <input ref={register} required={field.requried} maxLength={maxLength} className={classes.formFields} type={type} name={field.field} key={field.field} value={field.value} onChange={handleChange} />
                                                            </Box>
                                                        )
                                                    } else {
                                                        return (<>
                                                            {field.field !== "businessName" ? (
                                                                <Box key={field.field} className={classes.box}>
                                                                    <Typography className={classes.label}>{label}</Typography>
                                                                    <input ref={register} required={field.requried} maxLength={maxLength} className={classes.formFields} type={type} name={field.field} key={field.field} value={field.value} onChange={handleChange} />
                                                                </Box> ) : <></>}
                                                        </>
                                                        )
                                                    }
                                                }
                                            })}
                                        </Box>
                                        <Box className={classes.buttonContainer}>
                                            <Button component={RouterLink} to={`${process.env.PUBLIC_URL}/app/administration`} text={'Povratak'} />
                                            {
                                                btnLoading ?
                                                    (<LoadingIndicator />) :
                                                    dataUpdated ? (<Button type={"button"} text={"Dodaj novog "} action={refreshPage} />) : (<Button type={"submit"} text={'Spremi promjene'} />)

                                            }
                                            {
                                                dataUpdated !== null ?
                                                    dataUpdated === true ?
                                                        (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>) :
                                                        (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                                                    : (<></>)
                                            }
                                        </Box>
                                    </form>
                                </Hidden>
                                <Hidden mdUp>
                                    <form className={classes.formSm} onSubmit={handleSubmit(onSubmit)}>
                                        <Box className={classes.formContainer}>
                                            {fields.map(field => {
                                                let label = labelMapper(field.field)
                                                let maxLength = maxLengthMapper(field.field)
                                                let type = typeMapper(field.field)
                                                if (field.isSelect === true) {
                                                    if (field.conditionalRender === true) {
                                                        if (watchDonationType === '0') {
                                                            return (
                                                                <Box key={field.field} className={classes.box}>
                                                                    <Typography className={classes.label}>{label}</Typography>
                                                                    <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                        {
                                                                            field.options.filter(option => option[field.optionKey].id === -1).map(type => (
                                                                                <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                            )
                                                                        }
                                                                    </select>
                                                                </Box>
                                                            )
                                                        } else if (watchDonationType !== '0' && watchDonationType !== undefined && watchDonationType !== '') {
                                                            return (
                                                                <Box key={field.field} className={classes.box}>
                                                                    <Typography className={classes.label}>{label}</Typography>
                                                                    <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                        {
                                                                            field.options.filter(option => option[field.optionKey].id !== -1).map(type => (
                                                                                <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                            )
                                                                        }
                                                                    </select>
                                                                </Box>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <select id={field.field} ref={register} name={field.field} className={classes.formFields} required={true}>
                                                                    <option>

                                                                    </option>
                                                                    {
                                                                        field.options.map(type => (
                                                                            <option key={type[field.optionKey].id} value={type[field.optionKey].id}>{type[field.optionKey].name}</option>)
                                                                        )
                                                                    }
                                                                </select>
                                                            </Box>
                                                        )
                                                    }

                                                } else {
                                                    if(field.field==="paymentdat"){
                                                        return (                                        
                                                            <Box key={field.field} className={classes.box}>
                                                                <Typography className={classes.label}>{label}</Typography>
                                                                <input type="date" className="calendar" ref={register} name={field.field} key={field.field} selected={paymentDate} onChange={paymentDate=>{setPaymentDate(paymentDate)}} />
                                                            </Box>) }
                                                    if (watchSupporterType !== '0' || watchDonationType === "0") {
                                                        return (
                                                            <Box key={field.field} className={classes.box}>
                                                            <Typography className={classes.label}>{label}</Typography>
                                                            <input maxLength={maxLength} required={field.requried} className={classes.formFields} type={type} name={field.field} key={field.field} />
                                                        </Box>
                                                        )
                                                    } else {
                                                        return (<>
                                                            {field.field !== "businessName" ? (
                                                                 <Box key={field.field} className={classes.box}>
                                                                 <Typography className={classes.label}>{label}</Typography>
                                                                 <input maxLength={maxLength} required={field.requried} className={classes.formFields} type={type} name={field.field} key={field.field} />
                                                             </Box> ) : <></>}
                                                        </>
                                                        )
                                                    }
                                                }
                                            })}
                                        </Box>
                                        <Box className={classes.buttonContainer}>
                                            {
                                                btnLoading ?
                                                    (<LoadingIndicator />) :
                                                    dataUpdated ? (<Button type={"button"} text={"Dodaj novog "} action={refreshPage} />) : (<Button type={"submit"} text={'Spremi promjene'} />)
                                            }
                                            {
                                                dataUpdated !== null ?
                                                    dataUpdated === true ?
                                                        (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>) :
                                                        (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                                                    : (<></>)
                                            }
                                            <Button component={RouterLink} to={`${process.env.PUBLIC_URL}/app/administration`} text={'Povratak'} />
                                        </Box>
                                    </form>
                                </Hidden>
                            </>
                        )
                }
            </main>
        </>
    )
}
