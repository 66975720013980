import {useState} from 'react';
    const useReports = ()=>{

    const [supporterTypes,setSupporterTypes] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [statusTypes, setStatusTypes] = useState(null);
    return{
        setSupporterTypes,
        setPaymentTypes,
        supporterTypes,
        paymentTypes,
        statusTypes, 
        setStatusTypes
    }
}

export default useReports