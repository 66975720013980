const TableStyles={
    table:{
        backgroundColor: '#00172B',
        color: 'white ',
        borderSpacing: 0,
        border:'1px solid #CEA769'
       
        
    },
    reportTable:{
    },
    heading:{
        borderRight: '1px solid #CEA769',
        color: 'white ',
        width:'auto',
        fontWeight: 'bold',
        fontSize:'1.1rem'
    },
    cell:{
        borderBottom: '1px solid #CEA769',
        borderRight: '1px solid #CEA769',
        color: 'white ',
        padding: 10,
        fontSize: '1.1rem'
    },
    cellActive:{
        backgroundColor: '#11c211',
        borderBottom: '1px solid #CEA769',
        borderRight: '1px solid #CEA769',
        color: 'white ',
        padding: 10,
        fontSize: '1.1rem'
    },
    tableInputs:{
    },
    rbr:{
        width:'60%'
    },
    kbr:{
        width:'60%'
    },
    oib:{
        width: '75%'
    },
    adress:{
        width:'80%'
    },
    firstName:{
        width: '50%'
    },
    lastName:{
        width: '50%'
    },
    filters:{
        color: '#CEA769',
        alignItems:'center'
    },
    thDiv:{
        display:'flex',
        justifyContent:'center'
    },
    paginationBtn:{
        color:'white',
        borderColor: '#CEA769',
        backgroundColor:'#CEA769',
        '&:hover':{
            backgroundColor:'#B7935D',
        },
        fontWeight: 'bold',
        margin:5
    },
    pagination:{
        marginLeft: 10
    },
    indexSpan:{
        textAlgin: 'center',
        color: 'white',
        fontSize: '1.2rem',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
    },
    selectStyle:{
        backgroundColor: '#00172B',
        color: 'white',
        width: '250px',
        fontSize:'1rem'
    },
    selectStyleRep:{
        backgroundColor: '#00172B',
        color: 'white',
        fontSize:'1rem'
    },
    optionStyle:{
        backgroundColor: '#00172B',
        color: 'white',
        fontSize:'1rem'
    },
    selectStyleSm:{
        backgroundColor: '#00172B',
        color: 'white',
        fontSize:'1rem'
    },
    businessname:{
        borderBottom: '1px solid #CEA769',
        borderRight: '1px solid #CEA769',
        color: 'white ',
        padding: 10,
        fontSize: '1.1rem',
        width:'200px'
    },
    checkbox:{
        width:'25px',
        height:'25px'

    }
}
export default TableStyles