import React from 'react'
import Style from './LogoContainerStyle';
import { makeStyles } from '@material-ui/core/styles';
import dekodLogo from '../../../imgs/logowhite.png';
import zakladaLogo from '../../../imgs/zakladalogo.svg'
const useStyles = makeStyles(() => (Style));
export default function LogoContainer() {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <img className={classes.imgDekod} src={dekodLogo} alt="dekod"/>
            <img className={classes.imgZaklada} src={zakladaLogo} alt="zaklada"/>
        </div>
    )
}
