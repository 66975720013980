import background from '../../../imgs/hero_img1.jpg'
const SingInStyle ={
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 100

    },
    container:{
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
        backgroundRepeat:'no-repeat',
        height:'100vh',
        color: 'white',
        
    },
    inputs:{
       background: 'white',
       width: '20vw',
       borderRadius: 0,
    },
    box:{
        display:'flex',
        placeItems:'center',
        margin: 5
    },
    label:{
        height: '100%',
        background: '#CEA769',
        width: '20vw',
        display: 'grid',
        placeItems: 'center',
        marginRight: '1vw',
        textTransform: 'uppercase',
        fontSize: '1.3rem',
        fontWeight: 'bold'

    },
    submit:
        {
            color:'white',
            borderColor: '#CEA769',
            margin: 10,
            backgroundColor:'#CEA769',
            height:'40px',
            width:'200px',
            '&:hover':{
                backgroundColor:'#B7935D',
            },
            fontWeight: 'bold',
            fontSize: '1.3rem'
        
    },
    submitSm:{
        color:'white',
        borderColor: 'white',
        width: '10vw',
        marginTop: 10,
    },
    labelSm:{
        height: '5vh',
        background: '#CEA769',
        display: 'grid',
        placeItems: 'center',
        textTransform: 'uppercase',
        marginTop: 10,
    },
    inputsSm:{
        background: 'white',
        borderRadius: 0,
    },
    errorText:{
        textAlign: 'right',
        width:'100%',
        display: 'block'
    },
    indicator:{
        marginTop: 5
    }
}
export default SingInStyle