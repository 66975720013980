import React, {useEffect} from 'react'
import Header from '../../../genericcomponents/Header/GenericHeader';
import useLoading from '../../../genericcomponents/useLoading';
import Style from './MailHistoryStyle';
import { makeStyles, Hidden } from '@material-ui/core';
import {baseCalls} from '../../../../apiCalls/baseCalls';
import useMailHistory from './useMailHistory';
import useTables from '../../../tables/useTables';
import {Link as RouterLink} from 'react-router-dom'
import Button from '../../../genericcomponents/Button';
import Table from '../../../tables/MailHistoryTable';
const useStyles = makeStyles(() => (Style));
export default function MailHistory() {
    const classes = useStyles()
    const {spinner, isLoading, setIsLoading}= useLoading(true)
    const{
        setSupporterTypes,
        setPaymentTypes,
        setStatusTypes,
        supporterTypes,
        paymentTypes,
        statusTypes,
        mailTemplates,
        setMailTemplates
    } = useMailHistory()
    const {donations,
        setDonations,
        setInitialDonations,
        selectId, updateFilter,
        setStartSentDate,
        setEndSentDate,
        startSentDate,
        endSentDate,
    } = useTables()

    useEffect(() => {
        baseCalls.getSentMailsList().then(response=>{
            if(response.data.success===null){
                setDonations([])
                setInitialDonations([])
            }else{
                setDonations(response.data.success)
                setInitialDonations(response.data.success)
            }
            
            baseCalls.getSupporterTypes().then(response=>{
                setSupporterTypes(response.data.data)
                baseCalls.getPaymentTypes().then(response=>{
                    setPaymentTypes(response.data.data)
                    baseCalls.getStatusTypes().then(response=>{
                        setStatusTypes(response.data.data);
                        baseCalls.getMailTemplates().then(response=>{
                            setMailTemplates(response.data.data)
                            setIsLoading(false)
                        })
                        
                    }).catch(err=>{
                        console.log(err)
                        setIsLoading(false)
                    })
                }).catch(
                    err=>{
                        console.log(err)
                        setIsLoading(false)
                    }
                )
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
            })
        }).catch(err=>{
            console.log(err)
            setIsLoading(false)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Header text={"Pregled poslanih mailova"} active={"Pregled poslanih mailova"}/>
            {isLoading ? 
                (spinner)
            :(
                <>
                    <Button component={RouterLink} to={`${process.env.PUBLIC_URL}/app/sendMail`} text={'Povratak'}/>
                    <Hidden smDown>
                        <main>
                        <section className={classes.tableSection}>
                       <Table donations={donations} 
                        selectId={selectId} 
                        updateFilter={updateFilter}
                        setStartDateFilter={setStartSentDate}
                        setEndDateFilter={setEndSentDate}
                        startSentDate={startSentDate}
                        endSentDate={endSentDate}
                        supporterTypes={supporterTypes}
                        paymentTypes={paymentTypes}
                        statusTypes={statusTypes}
                        mailTemplates={mailTemplates}
                        /> 
                        </section>
                        </main>
                    </Hidden> 
                    <Hidden mdUp>
                            <main>
                            <section className={classes.tableSection}>
                         <Table donations={donations} 
                        selectId={selectId} 
                        updateFilter={updateFilter}
                        setStartDateFilter={setStartSentDate}
                        setEndDateFilter={setEndSentDate}
                        startSentDate={startSentDate}
                        endSentDate={endSentDate}
                        supporterTypes={supporterTypes}
                        paymentTypes={paymentTypes}
                        statusTypes={statusTypes}
                        mailTemplates={mailTemplates}
                        /> 
                        </section>
                            </main>
                    </Hidden>
                </>
            )}
        </>
    )
}
