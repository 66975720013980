import background from '../../../imgs/img3.png'
const DashboardStyles = {
    grid:{
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
        backgroundRepeat:'no-repeat',
        height:'100vh'
    },
    container:{
        height:'90%',
        display:'grid',
        placeItems:'center',
        flexGrow: 1,
    },
    navLinks:{
        height: '50%',
        width: '50%',
        display: 'grid',
        gridTemplateColumns:'1fr 1fr',
        textAlign: 'center'
       
    },
    navLinksSm:{
        height: '80%',
        width: '80%',
        display: 'grid',
    },
    links:{
        color: 'white',
        textTransform:'uppercase',
        fontWeight: 'bold',
        fontSize: '2.1em',
        backgroundColor:'none',
        height: '60%',
        width: '90%',
        display: 'grid',
        placeItems: 'center',
        padding: 5,
        border:'3px solid white',
        borderRadius: '15px',
        '&:hover':{
            color: '#B7935D',
            border:'3px solid #B7935D'
        },
    },
    linksSm:{
        color: 'white',
        textTransform:'uppercase',
        fontWeight: 'bold',
        border: '5px solid white',
        height: '60%',
        width: '90%',
        display: 'grid',
        placeItems: 'center',
        padding: 5
    },
    logOut:{
        display:'grid',
        placeItems:'end',
        
    },
    logOutLink:{
        color: 'white',
        textTransform:'uppercase',
        fontSize: '1.2rem',
        '&:hover':{
            color: '#CEA769',
        },
        marginTop: 10,
        marginRight:10
    }
}

export default DashboardStyles