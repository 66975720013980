import {
    LOG_IN

} from '../actions/actionTypes'
const initalState = {
    isLoggedIn: false,
}
export default function baseReducer(state = initalState, action) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isLoggedIn: action.payload
            }
        default:
            return state
    }
}