import React, { useState, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LOG_IN } from '../../../redux/actions/actionTypes';
import { baseCalls } from '../../../apiCalls/baseCalls';
import { Redirect } from "react-router-dom";
import SignInStyle from './SignInStyle';
import { Grid, Button } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { Hidden, CircularProgress } from '@material-ui/core';
import useLoading from '../../genericcomponents/useLoading'
import LogoContainer from '../../genericcomponents/LogoContainer/LogoContainer';
const useStyles = makeStyles(() => (SignInStyle));

export default function SignIn() {

    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [logInError, setLogInError] = useState('');
    const [logInErrorMessage, setLogInErrorMessage] = useState('');
    const [noEmail, setNoEmail] = useState(false);
    const [noPassword, setNoPassword] = useState(false);
    const { isLoggedIn } = useSelector(state => state.baseReducer);
    const {isLoading, setIsLoading} = useLoading(false)
    const dispatchRedux = useDispatch();
    function logIn() {
        return dispatch => {
            setIsLoading(true)
            if (logInError) {
                setLogInError(false)
                setNoEmail(false)
                setNoPassword(false)
            }
            if(username===""){
                setLogInErrorMessage('Korisničko ime je obavezno')
                setNoEmail(true)
                setLogInError(true)
                setIsLoading(false)
            }else if(password===""){
                setLogInErrorMessage('Lozinka je obavezna')
                setNoPassword(true)
                setLogInError(true)
                setIsLoading(false)
            }else{
                baseCalls.logIn(username, password).then(response => {
                    setIsLoading(false)
                    if(response.data.authorized){
                        dispatchRedux({
                            type: LOG_IN,
                            payload: response.data.authorized
                        })
    
                    }else {
                        setLogInErrorMessage(response.data.message)
                        setLogInError(true)
    
                }
            }).catch(err=>{
                    setIsLoading(false)
                    setLogInErrorMessage('Greška u komunikaciji')
                    setLogInError(true)
                })
            }
            
        }
    }
    function onLogIn(e) {
        e.preventDefault()
        dispatchRedux(logIn())
    }
    if (isLoggedIn) {
        return <Redirect to={`${process.env.PUBLIC_URL}/app`} />
    }
    console.log('refresh')
    return (
        <>
            <Grid container justify="center" direction="column"  className={classes.container}>
                    <form className={classes.form} onSubmit={onLogIn}>
                        <Hidden smDown>
                            <Box className={classes.box}>
                                <Typography className={classes.label}>Korisničko ime</Typography>
                                <OutlinedInput
                                type="text" 
                                className={classes.inputs}
                                error={noEmail}
                                onChange={e=>setUsername(e.target.value)}/>
                            </Box>
                            
                            <Box className={classes.box}>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.label}>Lozinka</Typography>
                                <OutlinedInput
                                type="password"
                                error={noPassword}
                                onChange={e=>setPassword(e.target.value)}
                                className={classes.inputs}/>
                            </Box>
                            {logInError ?
                            (<Typography align="center" color="error">
                                {logInErrorMessage}
                            </Typography>
                            ) : (<Fragment></Fragment>)}
                        {isLoading ?
                            (<CircularProgress className={classes.indicator}/>) :
                            (<Button
                                type={"submit"}
                                variant={"contained"}
                                className={classes.submit}
                                    >Prijava
                                </Button>)}
                        </Hidden>     
                        <Hidden mdUp>
                            <Box>
                                <Typography className={classes.labelSm}>Korisničko ime</Typography>
                                <OutlinedInput
                                type="text" 
                                error={noEmail}
                                className={classes.inputsSm}
                                onChange={e=>setUsername(e.target.value)}/>
                            </Box>
                            <Box >
                                <Typography className={classes.labelSm}>Lozinka</Typography>
                                <OutlinedInput
                                type="password"
                                error={noPassword}
                                className={classes.inputsSm}
                                onChange={e=>setPassword(e.target.value)}
                                />
                            </Box>
                            {logInError ?
                            (<Typography align="center" color="error">
                                {logInErrorMessage}
                            </Typography>
                            ) : (<Fragment></Fragment>)}
                        {isLoading ?
                            (<CircularProgress className={classes.indicator}/>) :
                            (<Button
                            type={"submit"}
                            variant={"contained"}
                            className={classes.submit}
                                >Prijava
                            </Button>)}
                        </Hidden>
                        
                    </form>
                    <LogoContainer/>
                </Grid>
                
            </>
    );
}