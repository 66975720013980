import React from 'react'
import { baseCalls } from '../../../apiCalls/baseCalls';
import Button from '../../genericcomponents/Button';
export default function ReportsBtn(props) {
    const { data, sortColumn, isSortedDesc } = props;

    let sortedDataByAmount = data.slice(0);

    sortedDataByAmount.sort(function (a, b) {
        if(isSortedDesc) {
            return a
        }
        else if(isSortedDesc === undefined) {
            return a[sortColumn] - b[sortColumn]
        }
        else {
            return b[sortColumn] - a[sortColumn]
        }
    });

    const exportExcel =()=>{
        baseCalls.getReport(sortedDataByAmount).then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Izvještaj.xlsx');
            document.body.appendChild(link);
            link.click();
            }).catch(err=>{
                console.log(err)
            })
    }
    return (
        <Button type={"button"} text={"Export XLS"} action={exportExcel} />
    )
}
