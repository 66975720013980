import React,{useEffect } from 'react'
import Header from '../../genericcomponents/Header/GenericHeader'
import Table from '../../tables/AdministrationTable'
import useTables from '../../tables/useTables'
import useLoading from '../../genericcomponents/useLoading';
import useAdministration from './useAdministration';
import { baseCalls } from '../../../apiCalls/baseCalls';
import {Link as RouterLink} from 'react-router-dom';
import Button from '../../genericcomponents/Button';
import { Typography, makeStyles, Hidden } from '@material-ui/core';
import Style from './AdministartionStyle';
const useStyles = makeStyles(() => (Style));
export default function Administration() {
    const classes = useStyles()
    const {isLoading,setIsLoading,spinner} = useLoading(true);
    const {donations,
        setDonations,
        setInitialDonations,
        selectedId,
        selectId,
        selectingError,
        triggerUnclickedError,
        errorMsg, updateFilter} = useTables()
    const {
        setSupporterTypes,setPaymentTypes,supporterTypes, paymentTypes
    }= useAdministration()

    useEffect(() => {
        baseCalls.getDonations().then(response=>{
            setDonations(response.data.data[0].f_get_donations)
            setInitialDonations(response.data.data[0].f_get_donations)
            baseCalls.getSupporterTypes().then(response=>{
                setSupporterTypes(response.data.data)
                baseCalls.getPaymentTypes().then(response=>{
                    setPaymentTypes(response.data.data)
                    setIsLoading(false)
                }).catch(
                    err=>{
                        console.log(err)
                        setIsLoading(false)
                    }
                )
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
            })
        }).catch(err=>{
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])
    return (
            <main className={classes.grid}>
                <Header text={'Administracija donatora'} active={'Administracija'}/>
                {
                    isLoading ?
                    (spinner)
                    :
                    (<>
                        <Hidden smDown>
                            <section className={classes.administrationControls}>
                                <section className={classes.dropdownSection}>
                                    <div className={classes.dropdownContainer}>
                                        <Typography  className={classes.dropdownLabel}>
                                            Tip donatora
                                        </Typography>
                                        <select name="supportertype" onChange={updateFilter} className={classes.selectStyle}>
                                            <option value={"all"} className={classes.optionStyle}>Svi</option>
                                            {
                                                supporterTypes.map(type=>(
                                                    <option key= {type.supporters.id} value={type.supporters.id} className={classes.optionStyle}> {type.supporters.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={classes.dropdownContainer}>
                                        <Typography className={classes.dropdownLabel}>
                                            Način plaćanja
                                        </Typography>
                                        <select name="paymenttype" onChange={updateFilter} className={classes.selectStyle}>
                                            <option value={"all"} className={classes.optionStyle}>Svi</option>
                                            {
                                                paymentTypes.map(type=>(
                                                <option key={type.payment.id} value={type.payment.id} className={classes.optionStyle}>{type.payment.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </section>
                                {selectedId===null ?
                                    (<>
                                        <Button text={'Administriraj'} action={triggerUnclickedError}/>
                                    {selectingError? (<span className={classes.errorMsg}>{errorMsg}</span>):(<></>)}
                                    </>
                                    ):
                                    (<Button component={RouterLink} text={'Administriraj'} to={`${process.env.PUBLIC_URL}/app/administer/${selectedId}`}/>)
                                }
                                <Button component={RouterLink} text={'Dodaj Donatora'} to={`${process.env.PUBLIC_URL}/app/addDonator`}/>
                            </section>
                            <section className={classes.tableSection}>
                                <Table donations={donations} selectId={selectId} updateFilter={updateFilter}/>
                            </section>
                    </Hidden>
                    <Hidden mdUp>
                        <section className={classes.administrationControls}>
                                <section className={classes.dropdownSectionSm}>
                                    <div className={classes.dropdownContainerSm}>
                                        <Typography  className={classes.dropdownLabelSm}>
                                            Tip donatora
                                        </Typography>
                                        <select onChange={updateFilter} className={classes.selectStyle}>
                                            <option value={"all"} className={classes.optionStyle}>Svi</option>
                                            {
                                                supporterTypes.map(type=>(
                                                    <option key= {type.supporters.id} value={type.supporters.id} className={classes.optionStyle}> {type.supporters.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={classes.dropdownContainerSm}>
                                        <Typography className={classes.dropdownLabelSm}>
                                            Način plaćanja
                                        </Typography>
                                        <select onChange={updateFilter} className={classes.selectStyle}>
                                            <option value={"all"} className={classes.optionStyle}>Svi</option>
                                            {
                                                paymentTypes.map(type=>(
                                                <option key={type.payment.id} value={type.payment.id} className={classes.optionStyle}>{type.payment.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </section>
                                {selectedId===null ?
                                    (<>
                                        <Button text={'Administriraj'} action={triggerUnclickedError}/>
                                    {selectingError? (<span className={classes.errorMsg}>{errorMsg}</span>):(<></>)}
                                    </>
                                    ):
                                    (<Button component={RouterLink} text={'Administriraj'} to={`${process.env.PUBLIC_URL}/app/administer/${selectedId}`}/>)
                                }
                                <Button component={RouterLink} text={'Dodaj Donatora'} to={`${process.env.PUBLIC_URL}/app/addDonator`}/>
                        </section>
                        <section className={classes.tableSection}>
                            <Table donations={donations} selectId={selectId} updateFilter={updateFilter}/>
                        </section>
                    </Hidden>
                    </>
                    )
                }
            </main>
    )
}
