import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SignIn from './components/basecomponents/SignIn/SignIn';
import Dashboard from './components/basecomponents/Dashboard/Dashboard';
import LogOut from './components/basecomponents/LogOut';
import DonatorsList from './components/basecomponents/Donators/DonatorsList';
import EditDonatorData from './components/basecomponents/Donators/EditDonatorData';
import Administration from './components/basecomponents/Administration/Administration';
import Reports from  './components/basecomponents/Reports/Reports';
import AdministerData from './components/basecomponents/Administration/AdministerData'
import AddDonator from './components/basecomponents/AddDonator/AddDonator';
import SendMail from './components/basecomponents/Mailing/SendMail/SendMail';
import MailHistory from './components/basecomponents/Mailing/MailHistory/MailHistory';
import ManageMailTemplates from './components/basecomponents/Mailing/ManageMailTemplates/ManageMailTemplates';
import { store } from './redux/store';
const ROUTES = [
    {
        path: `${process.env.PUBLIC_URL}/`,
        key: "ROOT",
        exact: true,
        component: SignIn
    },
    {
        path: `${process.env.PUBLIC_URL}/app`,
        key: "APP",
        exact: false,
        component: props => {
            if (!store.getState().baseReducer.isLoggedIn) {
                return <Redirect to={`${process.env.PUBLIC_URL}/`} />
            }
            return <RenderRoutes {...props} />
        },
        routes: [
            {
                path: `${process.env.PUBLIC_URL}/app`,
                key: "APP_ROOT",
                exact: true,
                component: Dashboard

            },
            {
                path: `${process.env.PUBLIC_URL}/app/logout`,
                key: "LOG_OUT",
                exact: true,
                component: LogOut
            },
            {
                path:`${process.env.PUBLIC_URL}/app/donators`,
                key:"DONATORS_LIST",
                exact: true,
                component: DonatorsList
            },
            {
                path:`${process.env.PUBLIC_URL}/app/donator-edit/:id`,
                key:"EDIT_DONATOR_DATA",
                exact: true,
                component:EditDonatorData
            },
            {
                path:`${process.env.PUBLIC_URL}/app/administration`,
                key:"EDIT_DONATOR_DATA",
                exact: true,
                component:Administration
            },
            {   
                path: `${process.env.PUBLIC_URL}/app/administer/:id`,
                key: 'ADMINISTER_DONATION',
                exact: true,
                component:AdministerData
            },
            {
                path:`${process.env.PUBLIC_URL}/app/reports`,
                key:"REPORTS",
                exact: true,
                component:Reports
            },
            {
                path:`${process.env.PUBLIC_URL}/app/addDonator`,
                key:"ADD_DONATOR",
                exact: true,
                component:AddDonator
            },
            {
                path:`${process.env.PUBLIC_URL}/app/sendMail`,
                key:"SEND_MAIL",
                exact: true,
                component:SendMail
            },
            {
                path:`${process.env.PUBLIC_URL}/app/mailHistory`,
                key:"MAIL_HISTORY",
                exact: true,
                component: MailHistory
            },
            {
                path:`${process.env.PUBLIC_URL}/app/manageMail`,
                key:"MANAGE_MAIL",
                exact: true,
                component:ManageMailTemplates
            },
        ]
    },


]
export default ROUTES
/**
 * Render a route with potential sub routes
 */
function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    );
}
/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
            <Route component={() => <h1>Not Found!</h1>} />
        </Switch>
    );
}