import { combineReducers } from 'redux';
import visualsReducer from './visualsReducer';
import {  LOG_OUT } from '../actions/actionTypes';
import { baseCalls } from '../../apiCalls/baseCalls'
import baseReducer from './baseReducer'
const appReducer = combineReducers({
    baseReducer,
    baseCalls,
    visualsReducer
})
const rootReducer = (state, action) => {
    if (action.type === LOG_OUT) {
        state = undefined;
        baseCalls.logOut()


    }
    return appReducer(state, action)
}
export default rootReducer;