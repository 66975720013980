import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import { SHOW_DRAWER, HIDE_DRAWER } from '../../../redux/actions/actionTypes';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Style from './HeaderStyles'
const useStyles = makeStyles(() => (Style))
    

export default function ButtonAppBar(props) {
    const {text,active}= props
    const dispatchRedux = useDispatch();
    const classes = useStyles();
    const { showDrawer } = useSelector(state => state.visualsReducer)
    function showDrawerFnc() {
        return dispatch => {
            dispatchRedux({
                type: SHOW_DRAWER
            })
        }
    }
    const links=[
        {
            text:'Početna',
            path: `${process.env.PUBLIC_URL}/app`,
            activeClass :'inactive'
        },
        {
            text:'Lista donatora',
            path: `${process.env.PUBLIC_URL}/app/donators`,
            activeClass :'inactive'
        },
        {
            text:'Administracija',
            path:`${process.env.PUBLIC_URL}/app/administration`,
            activeClass :'inactive'
        },
        {
            text:'Dodaj donatora',
            path:`${process.env.PUBLIC_URL}/app/addDonator`,
            activeClass :'inactive'
        },
        {
            text:'Izvještaji',
            path:`${process.env.PUBLIC_URL}/app/reports`,
            activeClass :'inactive'
        },
        {
            text:'Slanje maila',
            path:`${process.env.PUBLIC_URL}/app/sendMail`,
            activeClass :'inactive'
        },
        {
            text:'Upravljanje predlošcima',
            path:`${process.env.PUBLIC_URL}/app/manageMail`,
            activeClass :'inactive'
        },{
            text: 'Pregled poslanih mailova',
            path:`${process.env.PUBLIC_URL}/app/mailHistory`,
            activeClass :'inactive'
        }
]
    links.forEach(link=>{
        if(link.text===active){
            link.activeClass='active'
        }
    })
    function hideDrawer() {
        return dispatch => {
            dispatchRedux({
                type: HIDE_DRAWER
            })
        }
    }
    function onShowDrawerFnc() {
        dispatchRedux(showDrawerFnc())
    }
    function onHideDrawer() {
        dispatchRedux(hideDrawer())
    }
    useEffect(() => {
        dispatchRedux(hideDrawer())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <AppBar position="static" className={classes.header}>
                <Toolbar>
                    <IconButton edge="start" onClick={onShowDrawerFnc} className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {text}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer anchor={"left"} open={showDrawer} onClose={onHideDrawer} classes={{ paper: classes.paper }}>
                <List  >
                    {
                        links.map(link=>(
                            <ListItem key={link.text} className={`${classes.link}, ${classes[link.activeClass]}`}>
                                <Button fullWidth color="inherit" component={RouterLink} to={link.path}>
                                    <ListItemText>
                                        {link.text}
                                    </ListItemText>
                                </Button>
                            </ListItem>
                        ))
                    }
                </List>
                <Divider className={classes.divider}/>
                <List >
                    <ListItem className={classes.link}>
                        <Button fullWidth color="inherit" component={RouterLink} to={`${process.env.PUBLIC_URL}/app/logout`}>
                            <ListItemText>
                                Odjava
                            </ListItemText>
                        </Button>
                    </ListItem>
                </List>
            </Drawer>

        </div>
    );
}
