import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import DashboardStyles from './DashboardStyles'
import {Link} from '@material-ui/core'
import {Link as RouterLink} from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import Button from '../../genericcomponents/Button';
import LogoContainer from '../../genericcomponents/LogoContainer/LogoContainer';


const useStyles = makeStyles(() => (DashboardStyles));
export default function Dashboard() {
    const classes = useStyles()
    const links=[{
        path: `${process.env.PUBLIC_URL}/app/donators`,
        text: 'Lista Donatora'
        },
        {
        path: `${process.env.PUBLIC_URL}/app/administration`,
        text: 'Administracija donatora'
        },
        {
        path: `${process.env.PUBLIC_URL}/app/sendMail`,
        text: 'Slanje maila i obavijesti'
        },
        {path: '/',
        text: 'Postavke'
        },

    ]
    return (
        <main className={classes.grid}>
            <div className={classes.logOut}>
                <Button component={RouterLink} text={'Odjava'} to={`${process.env.PUBLIC_URL}/app/logout`}/>
            </div>
            <section className={classes.container}>
                <Hidden smDown>
                    <section className={classes.navLinks}>
                        {links.map(link=>(
                                <Link key={link.text} md={6} underline={"none"} component={RouterLink} to={link.path} className={classes.links} >
                                        {link.text}   
                                </Link>
                        ))} 
                    </section>
                </Hidden>
                <Hidden mdUp>
                    <section className={classes.navLinksSm}>
                        {links.map(link=>(
                                <Link key={link.text} md={6} underline={"none"} component={RouterLink} to={link.path} className={classes.linksSm} >
                                        {link.text}   
                                </Link>
                        ))} 
                    </section>
                </Hidden>
            </section>
            <LogoContainer/>
        </main>
    )
}

