import {useState} from 'react';

const useEditForm = (callback,initialData,neededFields)=>{
    const [data, setData] = useState(initialData);
    // eslint-disable-next-line
    const [neededFiledsArray, setNeededFieldsArray] = useState(neededFields)    
    const [dataUpdated,setDataUpdated]= useState(null);
    const [formResponseText, setFormResponseText]= useState('')

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch(name){
          case 'oib':
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
              setData({
                ...data,
                [name]: value,
              });
            }
            break;
          case 'amount':
            const regex= /^\d{1,6}(\.\d{1,2})?$/;
            if(regex.test(event.target.value) || event.target.value === ''){
              setData({
                ...data,
                [name]: value,
              });
            }
            break;
          default:
            setData({
              ...data,
              [name]: value,
            });
            break;
        }
        
      }

      const changeDate=(date)=>{
        setData({
          ...data,
          active_from:date
        })
      }

      const changePaymentDate=(paymentDate)=>{
        setData({
          ...data,
          paymentdat:paymentDate
        })
      }

      const getNeededFields = ()=>{
        let neededArray=[];
        let returnArray=[]
        let returnObj
        for (const [key, value] of Object.entries(data)) {
          if(neededFiledsArray.includes(key)){
            neededArray.push({[key]:value})
          }
        }
        neededArray.forEach(item=>{
          let index = neededFiledsArray.indexOf(Object.keys(item)[0])
          returnArray[index]=(item)        
        })
        returnObj= Object.assign({}, ...returnArray)
        return returnObj
      }
      /* duplicate cause add donator must have camel case */
      const labelMapper = (key)=>{
        let labelText;
        switch (key){
          case 'businessname':
            labelText='Naziv subjekta'
            break;
          case 'businessName':
            labelText='Naziv subjekta'
            break;
          case 'firstname':
            labelText='Ime'
            break;
          case 'firstName':
            labelText='Ime'
            break;
          case 'lastname':
            labelText='Prezime'
            break;
          case 'lastName':
            labelText='Prezime'
            break;
          case 'email':
            labelText='E-mail adresa'
            break;
          case 'address':
            labelText="Adresa"
            break;
          case 'addressnumber':
            labelText="KBR"
            break;
          case 'addressNumber':
            labelText="KBR"
            break;
          case 'city':
            labelText="Grad"
            break;
          case 'oib':
            labelText='OIB'
            break;
          case 'active_from':
            labelText='Aktivan od'
            break;
          case 'statusname':
            labelText='Status'
            break;
          case 'amount':
            labelText='Iznos';
            break;
          case 'paymentdat':
            labelText='Datum uplate';
            break;
          case 'paymentdat':
            labelText='Datum uplate';
            break;
          case 'supportertype':
              labelText='Tip podupiratelja';
              break;
          case 'supporterType':
              labelText='Tip podupiratelja';
              break;
          case 'donationtype':
              labelText ='Tip donatora';
              break;
          case 'donationType':
              labelText ='Tip donatora';
              break;
          case 'paymenttype':
              labelText= 'Tip plaćanja';
              break;
          case 'paymentType':
              labelText= 'Tip plaćanja';
              break;
          case 'addressnumber':
              labelText='Kućni broj';
              break;
          case 'addressNumber':
              labelText='Kućni broj';
              break;
          case 'status':
              labelText='Status';
              break;
          default:
            break;
        }
        return labelText
      }

      const maxLengthMapper = (key)=>{
        let maxLength;
        switch(key){
          case 'oib':
            maxLength=12
            break;
          default:
            maxLength=100
            break;
        }
        return maxLength
      }

      const typeMapper = (key)=>{
        let type;
        switch(key){
          case 'email':
            type= 'email'
            break;
          case 'amount':
            type= 'number';
            break;
          default:
            type='text'
            break;
        }
        return type
      }
      const requiredMapper=(key)=>{
        let required;
        switch(key){
          case 'firstname':
            required=true;
            break
          case 'lastname':
            required=true;
            break
          case 'amount':
            required=true;
            break;
          default:
            required=false;
          
        }
        return required;
      }
// eslint-disable-next-line
      return {
          handleChange,
          data,
          getNeededFields,
          labelMapper,
          dataUpdated,setDataUpdated,
          formResponseText,setFormResponseText,
          maxLengthMapper,
          typeMapper,
          requiredMapper,
          changeDate,
          changePaymentDate
      }
    
    
} 
export default useEditForm