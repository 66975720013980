import React from 'react'
import { useTable,useSortBy,useFilters,usePagination } from 'react-table'
import { makeStyles } from '@material-ui/core/styles';
import TableStyles from './TableStyles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import DatePicker  from 'react-datepicker';
import moment from 'moment';
const useStyles = makeStyles(() => (TableStyles));
 export default function Tables(props) {
    
    const classes = useStyles()
    const {donations, 
      supporterTypes,
      paymentTypes,
      statusTypes, updateFilter,
      setStartDateFilter,
      setEndDateFilter,
      startSentDate,
      endSentDate,
      mailTemplates,
      } = props
    const data = React.useMemo(
     () => donations,
     [donations]
   )
   const amountFilterOptions=[
    {text:'Svi',
      value:'all'},
    {
    text:'0-99',
    value: 1
    },
    {
    text:'100-499',
    value: 2
    },
    {
    text:'500-999',
    value: 3
    },
    {
    text:'1000+',
    value: 4
    },
  
  ]
   // Define a default UI for filtering
    const DefaultColumnFilter=({
    column: { filterValue, setFilter,inputClass },
  }) =>{
    return (
      <input
        className={classes[inputClass]}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    )
  }
    const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
    const columns = React.useMemo(
     () => [
       {
        Header:'Predložak',
        accessor: 'templatename',
        disableFilters: true,
        isTemplateFilter:true
     },
       {
        Header:'Datum slanja',
        accessor: 'senton',
        dateColumn:true,
        disableFilters: true,
        Cell: props =>  moment(props.row.original.senton).format("DD.MM.YYYY.")
     },
     {
      Header: 'Naziv',
      accessor: 'businessname',
      disableFilters: true,
    },
    {
        Header:'Tip',
        accessor: 'supportertypename',
        isSuppFilter:true
      },
      {
        Header: 'Ime',
        accessor: 'firstname',
      },
      {
        Header: 'Prezime',
        accessor: 'lastname',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Plaćanje',
        accessor: 'paymenttypename',
        disableFilters: true,
        isPaymentFilter:true
      },

      {
        Header: 'Status',
        accessor: 'statusname',
        disableFilters: true,
        isStatusFilter:true
      },

      {
        Header: 'Iznos',
        accessor: 'amount',
        disableFilters: true,
        amountColumn:true,
        Cell: props=>{
          let parsed= new Intl.NumberFormat('hr-HR', {style:'currency', currency:'EUR'}).format(props.row.original.amount)
          return(parsed)
        } 
      },
     ],
     []
   )
   // eslint-disable-next-line react-hooks/exhaustive-deps
    
   const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
   canPreviousPage,
   canNextPage,
   pageOptions,
   pageCount,
   gotoPage,
   nextPage,
   previousPage,
   setPageSize,
   state: { pageIndex, pageSize },
  } = useTable({ columns, data, defaultColumn, initialState:{pageIndex:0} }, useFilters, useSortBy,usePagination)
 
   return (
     <>
    <div className={classes.pagination}>
    <button className={classes.paginationBtn} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      <FirstPageIcon fontSize={"small"}/>
    </button>{' '}
    <button className={classes.paginationBtn} onClick={() => previousPage()} disabled={!canPreviousPage}>
      <NavigateBeforeIcon fontSize={"small"}/>
    </button>{' '}
    <button className={classes.paginationBtn} onClick={() => nextPage()} disabled={!canNextPage}>
      <NavigateNextIcon fontSize={"small"}/>
    </button>{' '}
    <button className={classes.paginationBtn} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      <LastPageIcon fontSize={"small"}/>
    </button>{' '}
    <span className={classes.indexSpan}>
      Stranica{' '}
      <strong>
        {pageIndex + 1} od {pageOptions.length}
      </strong>{' '}
    </span>
    <select
      className={classes.selectStyle}
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value))
      }}
    >
      {[{text:5, amount:5}, {text:10,amount:10}, {text:20, amount:20}, {text:"sve poslane mailove", amount:donations.length}].map(pageSize => (
        <option key={pageSize.text} value={pageSize.amount}>
          Prikaži {pageSize.text}
        </option>
      ))}
    </select>
  </div>
      <TableContainer className="mail-history-table">
      <Table {...getTableProps()} className={`${classes.table} ${classes.reportTable}`}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
               <TableCell align={'center'}
               {...column.getHeaderProps(column.getSortByToggleProps())}
             className={`${classes.heading} mail-history-cell`}>
               <div className={classes.thDiv}>
               {column.render('Header')}
               <span className={classes.filters}>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? (<ArrowDropUpIcon/>)
                      : (<ArrowDropDownIcon/>)
                    : ''}
                </span>
                </div>
             </TableCell>
              ))}
            </TableRow>
          ))}
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column =>
             {
               if(column.isSuppFilter){
                  return(
                    <TableCell key={column.Header} align={"center"} className={classes.heading}>
                      <select name="supportertype" onChange={updateFilter} className={classes.selectStyleRep}>
                      <option value={"all"} className={classes.optionStyle}>Svi</option>
                      {
                          supporterTypes.map(type=>(
                              <option key= {type.supporters.id} value={type.supporters.id} className={classes.optionStyle}> {type.supporters.name}</option>
                          ))
                      }
                  </select>
                </TableCell>
              )
               }else if(column.isPaymentFilter){
                 return(
                  <TableCell key={column.Header} align={"center"} className={classes.heading}>
                  <select name="paymenttype" onChange={updateFilter} className={classes.selectStyleRep}>
                        <option value={"all"} className={classes.optionStyle}>Svi</option>
                        {
                            paymentTypes.map(type=>(
                            <option key={type.payment.id} value={type.payment.id} className={classes.optionStyle}>{type.payment.name}</option>
                            ))
                        }
                    </select>
                  </TableCell>
             )
               }else if(column.isStatusFilter){
                 return (                  
                 <TableCell key={column.Header} align={"center"} className={classes.heading}>
                  <select name="status" onChange={updateFilter} className={classes.selectStyleRep}>
                       <option value={"all"} className={classes.optionStyle}>Svi</option>
                       {
                           statusTypes.map(type=>(
                           <option key={type.status.id} value={type.status.id} className={classes.optionStyle}>{type.status.name}</option>
                           ))
                       }
                   </select>
                 </TableCell>)
               }else if(column.amountColumn){
               return(
                <TableCell key={column.Header} align={"center"} className={classes.heading}>
                <select name="amount" onChange={updateFilter} className={classes.selectStyleSm}>
                    {
                    amountFilterOptions.map(option=>(
                    <option key={option.value} value={option.value}>{option.text}</option>
                    ))
                    })
                </select>
              </TableCell>
               )}
               else if(column.dateColumn){
                   return(
                       
                    <TableCell key={column.Header} align={"center"} className={classes.heading}>  
                        <div>Od:</div>      
                        <DatePicker
                        selected={startSentDate}
                        onChange={date => setStartDateFilter(date)}
                        isClearable
                        dateFormat={"dd/MM/yyyy"}
                        />
                        <div>Do:</div>      
                        <DatePicker
                        selected={endSentDate}
                        onChange={date => setEndDateFilter(date)}
                        isClearable
                        dateFormat={"dd/MM/yyyy"}
                        />
                    </TableCell>
                   )
               }
               else if(column.canFilter){
                return(
                  <TableCell key={column.Header} align={"center"} className={classes.heading}>{column.render('Filter')}</TableCell>
                )
              }else if(column.isTemplateFilter){
                return(

                  <TableCell key={column.Header} align={"center"} className={classes.heading}>
                    <select name="template" onChange={updateFilter} className={classes.selectStyleSm}>
                          <option value={"all"} className={classes.optionStyle}>Svi</option>
                          {
                          mailTemplates.map(option=>(
                          <option key={option.templates.id} value={option.templates.id}>{option.templates.name}</option>
                          ))
                          })
                      </select>
                  </TableCell>
                )
              }
               else{
                return (
                  <TableCell key={column.Header} align={"center"} className={classes.heading}>
                  </TableCell>  
                  )
               }
             
             }
             
             )
            }
          </TableRow>
          ))}

        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <TableRow   {...row.getRowProps()}>
                {row.cells.map(cell => {
                  let activeClass= classes.cell
                  if(cell.value==='Aktivan'){
                    activeClass=classes.cellActive
                  }
                  if(cell.column.Header==="Naziv"){
                    activeClass=classes.businessname
                  }
                  return (
                    <TableCell align={"center"}  className={activeClass}
                      {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell >
                  )
                })}
              </TableRow >
            )
          })}
        </TableBody>
      </Table>
      </TableContainer>
     </>
   )
 }