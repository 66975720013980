import React,{useEffect, useState} from 'react'
import Header from '../../genericcomponents/Header/GenericHeader'
import Table from '../../tables/ReportsTable'
import useTables from '../../tables/useTables'
import useLoading from '../../genericcomponents/useLoading'
import { baseCalls } from '../../../apiCalls/baseCalls';
import { CircularProgress } from '@material-ui/core'
import ReportsBtn from './ReportsBtn';
import useReports from './useReports';
export default function Reports() {
    const {isLoading, setIsLoading, spinner} = useLoading(true);
    const [clickedSortColumn, setClickedSortColumn] = useState("")
    const [isSortedDesc, setIsSortedDesc] = useState("")

    const { 
        donations,
        setDonations,
        setInitialDonations,
        updateFilter} = useTables()
    const {setSupporterTypes,
        setPaymentTypes,
        supporterTypes,
        paymentTypes,
        statusTypes, 
        setStatusTypes}= useReports()
        useEffect(() => {
            baseCalls.getDonations().then(response=>{
                setDonations(response.data.data[0].f_get_donations)
                setInitialDonations(response.data.data[0].f_get_donations)
                baseCalls.getSupporterTypes().then(response=>{
                    setSupporterTypes(response.data.data)
                    baseCalls.getPaymentTypes().then(response=>{
                        setPaymentTypes(response.data.data)
                        baseCalls.getStatusTypes().then(response=>{
                            setStatusTypes(response.data.data);
                            setIsLoading(false)
                        }).catch(err=>{
                            console.log(err)
                            setIsLoading(false)
                        })
                    }).catch(
                        err=>{
                            console.log(err)
                            setIsLoading(false)
                        }
                    )
                }).catch(err=>{
                    console.log(err)
                    setIsLoading(false)
                })
            }).catch(err=>{
                console.log(err)
            })
            // eslint-disable-next-line
        }, [])

        const handleSort =  (value) => {
            setClickedSortColumn(value.id)
            setIsSortedDesc(value.isSortedDesc)
            console.log(value);
        }

    return (
        <main>
            <Header text={'Izvještaji'} active={"Izvještaji"}/>
            {
                isLoading ?
                ( spinner )
                :
                (<>
                    <ReportsBtn data={donations} sortColumn={clickedSortColumn} isSortedDesc={isSortedDesc}/>
                    <Table donations={donations} updateFilter={updateFilter} 
                        supporterTypes={supporterTypes}
                        paymentTypes={paymentTypes}
                        statusTypes={statusTypes}
                        onClick={(value) => handleSort(value)}
                        />
                </>)
            }
            
        </main>
    )
}