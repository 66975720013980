import React, {  useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Style from './DonatorsListStyle'
import Table from '../../tables/DonatorsTable'
import { baseCalls } from '../../../apiCalls/baseCalls';
import {Link as RouterLink} from 'react-router-dom';
import Header from '../../genericcomponents/Header/GenericHeader'
import Button from '../../genericcomponents/Button'
import {Button as MUIButton, Hidden} from '@material-ui/core/';
import useLoading from '../../genericcomponents/useLoading';
import useTables from '../../tables/useTables'
const useStyles = makeStyles(() => (Style));
export default function DonatorsList() {
    const classes = useStyles()
    const {isLoading,setIsLoading,spinner} = useLoading(true);
    const {btnFilterDonations, 
        donations,
        headingText,
        setDonations,
        setInitialDonations,
        selectedId,
        selectId,
        selectingError,
        triggerUnclickedError,
        errorMsg,
        selectFilterDonations} = useTables()
    const options = [{text:'Svi donatori', supporterType: 'all'}, 
                    {text: 'Jednokratni donator', supporterType:'oneTime'}, 
                    {text:'Podupiratelj - Fizička osoba', supporterType:'person'},
                    {text:'Podupiratelj - Udruga, Klub, Društvo', supporterType:'ukd'},
                    {text:'Podupiratelj - Pravna osoba', supporterType:'legalPerson'}]
    useEffect(() => {
        baseCalls.getDonations().then(response=>{
            setDonations(response.data.data[0].f_get_donations)
            setInitialDonations(response.data.data[0].f_get_donations)
            setIsLoading(false)
        }).catch(err=>{
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])
    const fullButtonAction =(e)=>{
        btnFilterDonations(e);
    }
    const selectUpdateAction = (e)=>{
        selectFilterDonations(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <main className={classes.grid}>
            <Header text={'Lista donatora'} active={'Lista donatora'}/>
        {
            isLoading ? (spinner):(
                <>
                    <Hidden smDown>
                        <section className={classes.layout}>
                            <nav className={classes.sideNav}>
                            {
                                options.map(option=>(
                                    <MUIButton value={option.supporterType} name={option.supporterType} key={option.text} className={classes.sideNavLink} onClick={fullButtonAction}>
                                        {option.text}
                                    </MUIButton>
                                    ))
                            }
                            </nav>
                            <section>
                                <h1 className={classes.generalHeading}>{headingText}</h1>
                                {selectedId===null ?
                                    (<>
                                        <Button text={'Uredi podatke'} action={triggerUnclickedError}/>
                                        {selectingError? (<span className={classes.errorMsg}>{errorMsg}</span>):(<></>)}
                                    </>
                                    ):
                                    (<Button component={RouterLink} text={'Uredi podatke'} to={`${process.env.PUBLIC_URL}/app/donator-edit/${selectedId}`}/>)
                                    }

                                    <Table donations={donations} selectId={selectId}/>
                                
                            </section>
                        </section>
                    </Hidden>
                    <Hidden mdUp>
                        <section>
                            <select onChange={selectUpdateAction} className={classes.select}>
                            {
                                options.map(option=>(
                                    <option value={option.supporterType} name={option.supporterType} key={option.text} className={classes.selectOption} onClick={fullButtonAction}>
                                        {option.text}
                                    </option>
                                    ))
                            }
                            </select>
                        </section>
                        <section>
                            <h1 className={classes.generalHeading}>{headingText}</h1>
                            {selectedId===null ?
                                (<>
                                    <Button text={'Uredi podatke'} action={triggerUnclickedError}/>
                                {selectingError? (<div className={classes.errorMsg}>{errorMsg}</div>):(<></>)}
                                </>
                                ):
                                (<Button component={RouterLink} text={'Uredi podatke'} to={`${process.env.PUBLIC_URL}/app/donator-edit/${selectedId}`}/>)
                                }

                                <Table donations={donations} selectId={selectId}/>
                        </section>
                    </Hidden>
                </>
                )
        }
        </main>  
    )
}
