import {useState} from 'react';

const useSendMail = ()=>{

    const [supporterTypes,setSupporterTypes] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null)
    const [statusTypes, setStatusTypes]= useState(null)

    return{
        setSupporterTypes,
        setPaymentTypes,
        setStatusTypes,
        supporterTypes,
        paymentTypes,
        statusTypes,
        
    }
}

export default useSendMail