import React, {useState, useEffect} from 'react'
import Form from './AdministartionDataForm';
import {useParams} from 'react-router-dom';
import { baseCalls } from '../../../apiCalls/baseCalls';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../genericcomponents/Header/GenericHeader'
import Style from '../Donators/EditDonatorDataStyle';
import useLoading from '../../genericcomponents/useLoading';
const useStyles = makeStyles(() => (Style));

export default function AdministerData() {
    const classes = useStyles();
    let {id} = useParams();
    const [supporterTypes, setSupporterTypes] = useState(null);
    const [donatorData, setDonatorData] = useState(null);
    const [statusTypes, setStatusTypes]= useState(null)
    const {isLoading, setIsLoading,spinner} = useLoading(true)
    useEffect(() => {
        baseCalls.getDonatorData(id).then(response=>{
            setDonatorData(response.data.data[0].f_get_donations[0]);
            baseCalls.getSupporterTypes().then(response=>{
                setSupporterTypes(response.data.data)
                baseCalls.getStatusTypes().then(response=>{
                    setStatusTypes(response.data.data)
                    setIsLoading(false)
                })
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
            })
        }).catch(err=>{
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (
        <main className={classes.grid}>
            <Header text={'Administracija'}/>
            {!isLoading? (
                    <Form  donatorData={donatorData} supporterTypes={supporterTypes} statusTypes={statusTypes}/>
                ):
                (
                    spinner
                )
            }
            
        </main>
    )
}
