import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid/Grid";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    indicator: {
        display:'grid',
        placeItems: 'center'
    },
}));
export default function LoadingIndicator() {

    const classes = useStyles();
    return (
        <Grid container  className={classes.indicator} >
            <CircularProgress />
        </Grid >
    )
}
