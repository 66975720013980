const Style={
    grid:{
        height:'100vh',
        flexGrow: 1,
    },
    dropdownContainer:{
        display: 'grid',
        margin: 10,
    },
    dropdownContainerSm:{
        margin: 10,
    },
    dropdownSection:{
        display: 'flex',
        
    },
    dropdownSectionSm:{
        display:'block'
    },
    dropdownLabel:{
        display:'inline',
        color: 'white',
        fontSize: '1.2rem'
    },
    dropdownLabelSm:{
        color: 'white',
        fontSize: '1.2rem'
    },
    errorMsg:{
        color: '#cc0000',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif'
    },
    selectStyle:{
        backgroundColor: '#00172B',
        color: 'white',
        width: '250px',
        fontSize:'1rem'
    },
    optionStyle:{
        backgroundColor: '#00172B',
        color: 'white',
        fontSize:'1rem'
    },
    mailControlSection:{
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0.5rem'
    },
    mailControlSectionSm:{
        display: 'block',
    },
    tableSection:{
        padding:'20px'
    }
}
export default Style;