import {useEffect, useState,useRef} from 'react';
import _ from 'lodash';

const useTables = ()=>{
    const [donations, setDonations] = useState()
    const [headingText, setHeadingText]= useState('Svi donatori')
    const [initialDonations,setInitialDonations]= useState(null)
    const [selectingError,setSelectingError] = useState(false)
    const [selectedId, setSelectedId] = useState(null);
    const [suppFilter,setSuppFilter]= useState('all');
    const [payFilter,setPayFilter]= useState('all');
    const [statFilter,setStatFilter]= useState('all');
    const [amountFilter, setAmountFilter]= useState('all');
    const [templateFilter, setTemplateFilter]= useState('all')
    const [startDateFilter, setStartDateFilter]= useState(null);
    const [endDateFilter, setEndDateFilter]= useState(null);
    const [startActiveFromFilter, setStartActiveFromFilter]= useState(null);
    const [endActiveFromFilter, setEndActiveFromFilter]= useState(null);
    const [startSentDate, setStartSentDate]= useState(null);
    const [endSentDate, setEndSentDate]= useState(null);
    const [mailRecipientList, setMailRecipientList] = useState([]);
    const [mailSelectedAll, setMailSelectedAll]= useState(false)
    const mailRef = useRef()
    mailRef.current= mailRecipientList;
    const errorMsg= 'Prije uređivanja odaberite donaciju!'
    const btnFilterDonations = (event)=>{
        setSelectedId(null)
        let filter;
        if(initialDonations===null){
            setInitialDonations(donations)
        }
        const {value} = event.currentTarget
        switch(value){
            case 'all':
                setDonations(initialDonations);
                setHeadingText('Svi donatori');
                break;
            case 'oneTime':
                filter= initialDonations.filter(element=> element.supportertype===-1);
                setDonations(filter);
                setHeadingText('Jednokratni donator')
                break;
            case 'person':
                filter= initialDonations.filter(element=> element.supportertype===0);
                setDonations(filter);
                setHeadingText('Podupiratelj – fizička osoba')
                break;
            case 'ukd':
                filter= initialDonations.filter(element=> element.supportertype===1 ||element.supportertype===3 ||element.supportertype===4);
                setDonations(filter);
                setHeadingText('Podupiratelj – udruga, klub, društvo')
                break;
            case 'legalPerson':
                filter= initialDonations.filter(element=> element.supportertype===2);
                setDonations(filter);
                setHeadingText('Podupiratelj – pravna osoba')
                break;
            default:
                setDonations(initialDonations);
                setHeadingText('Svi donatori');
                break;
        }
    }
    // eslint-disable-next-line
    const selectFilterDonations = (event)=>{
        setSelectedId(null)
        let filter;
        if(initialDonations===null){
            setInitialDonations(donations)
        }
        const {value} = event.currentTarget
        switch(value){
            case 'all':
                setDonations(initialDonations);
                setHeadingText('Svi donatori');
                break;
            case 'oneTime':
                filter= initialDonations.filter(element=> element.supportertype===-1);
                setDonations(filter);
                setHeadingText('Jednokratni donator')
                break;
            case 'person':
                filter= initialDonations.filter(element=> element.supportertype===0);
                setDonations(filter);
                setHeadingText('Podupiratelj – fizička osoba')
                break;
            case 'ukd':
                filter= initialDonations.filter(element=> element.supportertype===1 ||element.supportertype===3 ||element.supportertype===4);
                setDonations(filter);
                setHeadingText('Podupiratelj – udruga, klub, društvo')
                break;
            case 'legalPerson':
                filter= initialDonations.filter(element=> element.supportertype===0);
                setDonations(filter);
                setHeadingText('Podupiratelj – pravna osoba')
                break;
            default:
                setDonations(initialDonations);
                setHeadingText('Svi donatori');
                break;
        }
    }
    const selectId = (e)=>{
        if(selectingError===true){
            setSelectingError(false)
        }
        setSelectedId(e)
    }
    const triggerUnclickedError =()=>{
        setSelectingError(true)
    }
    const updateFilter = (event)=>{
        const {name,value}= event.currentTarget;
        switch(name){
            case 'supportertype':
                setSuppFilter(value);
                break;
            case "paymenttype":
                setPayFilter(value);
                break;
            case "status":
                setStatFilter(value)
                break;
            case "amount":
                setAmountFilter(value);
                break;
            case "template":
                setTemplateFilter(value);
                break;
            default:
                break;
        }
    }
    const selectFilter =()=>{
        setSelectedId(null);
        if(initialDonations===null){
            setInitialDonations(donations)
        }
        let filters={}
        if(suppFilter!=="all"){
           filters.supportertype= parseInt(suppFilter)
        }
        if(payFilter!=="all" ){
            filters.paymenttype= parseInt(payFilter)
        }
        if(statFilter!=="all"){
            filters.status=statFilter
        }
        if(templateFilter!=="all"){
            filters.templateid=parseInt(templateFilter);
        }
        let data= _.filter(initialDonations,_.matches(filters))
        if(amountFilter!=="all"){
            switch(parseInt(amountFilter)){
                case 1:
                    data=_.filter(data,({amount})=>parseFloat(amount)>=parseFloat(0) && parseFloat(amount)<=parseFloat(99));
                    break;
                case 2:
                    data=_.filter(data,({amount})=> parseFloat(amount)>=parseFloat(100) && parseFloat(amount)<=parseFloat(499));
                    break;
                case 3:
                    data=_.filter(data,({amount})=> parseFloat(amount)>=parseFloat(500) && parseFloat(amount)<=parseFloat(999));
                    break;
                case 4:
                    data=_.filter(data,({amount})=> parseFloat(amount)>=parseFloat(1000));
                    break;
                default:
                    break;
            }
            
        }
        if(startDateFilter!==null){
            data= _.filter(data,({createdat})=> new Date(startDateFilter)<=new Date(createdat))
        }
        if(endDateFilter!==null){
            data= _.filter(data,({createdat})=> new Date(endDateFilter)>= new Date(createdat))
        }
        if(startActiveFromFilter!==null){
            data= _.filter(data,({active_from})=> new Date(startActiveFromFilter)<= new Date(active_from))
        }
        if(endActiveFromFilter!==null){
            data= _.filter(data,({active_from})=> new Date(endActiveFromFilter)>= new Date(active_from))
        }
        if(startSentDate!==null){
            data= _.filter(data,({senton})=> new Date(startSentDate)<= new Date(senton))
        }
        if(endSentDate!==null){
            data= _.filter(data,({senton})=> new Date(endSentDate)>= new Date(senton))
        }
        setDonations(data)
    }
    const controlMailRecipientList = (e)=>{
        const isChecked= e.currentTarget.checked;
        if(isChecked===true){
            donations.forEach((donation) => {
                if(donation.id===parseInt(e.currentTarget.value)){
                    setMailRecipientList(mailRecipientList=>[...mailRecipientList, donation]);
                }
            });
        }else{
            let newList= mailRef.current.filter((item)=>parseInt(item.id)!== parseInt(e.currentTarget.value))
            setMailRecipientList(newList)
        }
    }
    const selectAllRecipients =()=>{
            if(mailSelectedAll){
                setMailSelectedAll(false)
                setMailRecipientList([])
                
            }else{
                setMailSelectedAll(true)
                let sendAll=donations.filter(donation=>donation.email!=="" && donation.email!==null)
                setMailRecipientList(sendAll)
            }
    }
    useEffect(() => {
        selectFilter(); // This is be executed when `loading` state changes
    }, [suppFilter,payFilter,statFilter,amountFilter,
        startDateFilter,endDateFilter,startActiveFromFilter,endActiveFromFilter,mailRef, endSentDate,templateFilter
    ,startSentDate])
    return{
        btnFilterDonations, 
        donations,
        headingText,
        setDonations,
        setInitialDonations,
        selectedId,
        setSelectedId,
        selectingError,
        selectId,
        triggerUnclickedError,
        errorMsg,
        selectFilter,
        updateFilter,
        setStartDateFilter,
        setEndDateFilter,
        setStartActiveFromFilter,
        setEndActiveFromFilter,
        startDateFilter,
        endDateFilter,
        startActiveFromFilter,
        endActiveFromFilter,
        controlMailRecipientList,
        mailRef,
        mailRecipientList,
        mailSelectedAll,
        selectAllRecipients,
        setStartSentDate,
        setEndSentDate,
        startSentDate,
        endSentDate,
        templateFilter, 
    }
}
export default useTables