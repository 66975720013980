import axios from 'axios';
const instance = axios.create({})

instance.interceptors.response.use(function (response) {
    return response
}, function (err) {
    if (err.response.status === 401) {
                window.location = `${process.env.PUBLIC_URL}/app/logout`
        
    }
})
export default instance