import React, {useEffect, useState, useRef} from 'react'
import { EditorState,ContentState, convertToRaw} from 'draft-js';
import Button from '../../../genericcomponents/Button';
import 'draft-js/dist/Draft.css';
import Header from '../../../genericcomponents/Header/GenericHeader';
import { baseCalls } from '../../../../apiCalls/baseCalls';
import useLoading from '../../../genericcomponents/useLoading';
import { makeStyles, Typography  } from '@material-ui/core';
import Style from './ManageMailTemplateStyle'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles(() => (Style));

export default function ManageMailTemplates() {
    const classes = useStyles();
    const [readOnly, setReadOnly]= useState(true)
    const {spinner, isLoading, setIsLoading}= useLoading(true)
    const [mailTemplates, setMailTemplates]= useState(null);
    const [mailSubject, setMailSubject]= useState('');
    const [mailBody, setMailBody]= useState('');
    const [selectedId, setSelectedId]=useState(null);
    const [templateUpdated, setTemplateUpdated] = useState(null);
    const [templateUpdatedResponse,setTemplateUpdatedResponse]= useState('');
    const [editorStateInHtml, setEditorStateInHtml] = useState();
    const editor = React.useRef(null);
    const mailBodyRef= useRef();
    mailBodyRef.current=mailBody

    const calcState = (value) => {
        let editorState;
        if(value){
            const blocksFromHTML= htmlToDraft(value) 
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
              );

              editorState=EditorState.createWithContent(state)
        }else{
            editorState=EditorState.createEmpty();
        }
        return editorState
      }
      const [editorState, setEditorState] = useState(calcState(mailBodyRef.current));

      useEffect(() => {
        setEditorState(calcState(mailBodyRef.current));
      }, [mailBodyRef.current]);
      
    const saveTemplate= ()=>{
          let value = editorStateInHtml;
          baseCalls.updateMailTemplates(selectedId, mailSubject,value).then(response=>{
              setTemplateUpdated(true);
              setTemplateUpdatedResponse("Predložak uspješno ažuriran, stranica će se osvježiti kako bi dorade bile vidljive.")
              setTimeout(() => {
                window.location.reload();
              }, 4000);
          }).catch(err=>{
            setTemplateUpdated(false)
            setTemplateUpdatedResponse("Predložak neuspješno ažuriran")
          });
      }

    const toggleReadOnly= ()=> {
          if(readOnly){
              setReadOnly(false)
          }else{
            setReadOnly(true)
          }
      }

    const chooseTemplate =(e)=>{
          let id= e.currentTarget.value;
          setTemplateUpdated(null)
          if(id!=='null'){
            setSelectedId(parseInt(e.currentTarget.value))
            let selectedTemplate=mailTemplates.filter(temp=>temp.templates.id===parseInt(id))[0];
            setMailSubject(selectedTemplate.templates.subject);
            setMailBody(selectedTemplate.templates.body)
          }else{
              setSelectedId(null)
          }

      }

      const changeSubject = (e)=>{
          setMailSubject(e.currentTarget.value)
      }

      useEffect(() => {
          baseCalls.getMailTemplates().then(response=>{
              setMailTemplates(response.data.data)
              setIsLoading(false)
          }).catch(err=>{
                setIsLoading(false)
                console.log(err)
          })
      },[])

      const onEditorStateChange = editorValue => {          
        setEditorStateInHtml(draftToHtml(convertToRaw(editorValue.getCurrentContent())))
        setEditorState(editorValue)
      };

    return (
        <>
            <Header text={"Upravljanje predlošcima"} active={"Upravljanje predlošcima"}/>
            {
                isLoading ? 
                    (spinner):(
                <>
                    <div>             
                        <select onChange={chooseTemplate} className={classes.selectStyle}>
                        <option value={'null'} className={classes.optionStyle}>-</option> 
                        {mailTemplates.map(template=>(
                                <option key={template.templates.id} value={template.templates.id} className={classes.optionStyle}>{template.templates.name}</option>
                                ))} 
                        </select>
                        <Button text={"Uredi predložak"} action={toggleReadOnly}/>
                        <Button text={"Spremi predložak"} action={saveTemplate}/>
                    </div>   

                    {
                        readOnly ? (
                        <Typography className={classes.errorMsg}>Uređivanje nije omogućeno!</Typography>
                        ):(<Typography className={classes.updateSuccess}>Uređivanje omogućeno!</Typography>)
                    }
                    {
                        templateUpdated !==null ?
                            templateUpdated===true ? (
                            <Typography className={classes.updateSuccess}>{templateUpdatedResponse}</Typography>)
                            :(<Typography className={classes.errorMsg}>{templateUpdatedResponse}</Typography>)
                            :(<></>)
                    }
                    <section id={"edit-mail-section"}>
                        <input readOnly={readOnly} className={"template-name"} type={"text"} placeholder={"Naziv predloška"} value={mailSubject} onChange={changeSubject}/>
                        <div className={"editor-div"}>
                        
                            <Editor
                                toolbarHidden={false}
                                readOnly={readOnly}
                                ref={editor}
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        
                    </section>
                </>)
            }
           
        </>
    )
}
