import background from '../../../imgs/img10.jpg'
const Style ={
    grid:{
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        height:'100%',
        flexGrow: 1,
    },
}
export default Style;