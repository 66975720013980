import {useState} from 'react';

const useAddDonators = ()=>{
    const initialForm={
        businessName:'',
        firstName:'',
        lastName:'',
        email:'',
        address: '',
        addressNumber:'',
        city:'',
        oib:'',
        status:'',
        amount:'',
        paymentdat:''        
    }

    const [supporterTypes,setSupporterTypes ] = useState(null);
    // eslint-disable-next-line
    const [paymentTypes, setPaymentTypes]= useState(null);
    const [donationTypes, setDonationTypes]= useState(null);
    // eslint-disable-next-line
    const [statusTypes, setStatusTypes]= useState(null)
    const [inputFields, setInputFields]= useState(initialForm)

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch(name){
          case 'oib':
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                setInputFields({
                ...inputFields,
                [name]: value,
              });
            }
            break;
        case 'amount':
            const regex= /^\d{1,6}(\.\d{1,2})?$/;
            if(regex.test(event.target.value) || event.target.value === ''){
                setInputFields({
                ...setInputFields,
                [name]: value,
                });
                
            }
            break;
          default:
            setInputFields({
              ...inputFields,
              [name]: value,
            });
            break;
        }        
      }

    const changePaymentDate=(value)=>{
        setInputFields({
            ...inputFields,
            paymentdat: value
        })
    }

    const fields=[
        {
            field:"donationType",
            isSelect:true,
            optionKey:"donation",
            options:donationTypes,
            defaultValue: '0'
        },
        {
            field:"supporterType",
            isSelect:true,
            optionKey:"supporters",
            options:supporterTypes,
            conditionalRender:true
        },/*
        {
            field:"paymentType",
            isSelect:true,
            optionKey:"payment",
            options:paymentTypes
        },*/
        {
            field:"businessName",
            isSelect:false,
            type:'text',
            value: inputFields.businessname,
        },
        {
            field:"firstName",
            isSelect:false,
            type:'text',
            value: inputFields.firstname,
            requried:true
        },
        {
            field:"lastName",
            isSelect:false,
            type:'text',
            value: inputFields.lastname,
            requried:true
        },
        {
            field:'email',
            isSelect:false,
            type:'email',
            value: inputFields.email,
        },
        {
            field:'address',
            isSelect:false,
            type:'text',
            value: inputFields.address,
        },
        {
            field:'addressNumber',
            isSelect:false,
            type:'text',
            value: inputFields.addressnumber,
        },
        {
            field: 'city',
            isSelect:false,
            type: 'text',
            value:inputFields.city,
        },
        {
            field:'oib',
            isSelect:false,
            type:'text',
            inputRestriction:true,
            value:inputFields.oib,
        },/*
        {
            field:'status',
            isSelect:true,
            optionKey:"status",
            options:statusTypes,
        },*/
        {
            field:"paymentdat",
            isSelect:false,
            type:"number",
            value: inputFields.paymentdat
        },   
        {
            field:"amount",
            isSelect:false,
            type:"number",
            value: inputFields.amount,
            requried:true
        } 
    ]

// eslint-disable-next-line
    return{
        fields,
        setSupporterTypes,
        setDonationTypes,
        setPaymentTypes,
        setStatusTypes,
        handleChange,
        changePaymentDate
    }
}
export default useAddDonators