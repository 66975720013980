import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { LOG_OUT } from '../../redux/actions/actionTypes';
import { Redirect } from 'react-router-dom'
export default function LogOut() {
    const dispatchRedux = useDispatch()
    function reduxLogOut() {
        return dispatch => {
            dispatchRedux({
                type: LOG_OUT
            })
        }
    }
    useEffect(() => {
        dispatchRedux(reduxLogOut())
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Redirect to={`${process.env.PUBLIC_URL}/app`} />
    )
}
