import React,  {useState} from 'react';
import LoadingIndicator from './LoadingIndicator';
const useLoading= (inital)=>{
    const [isLoading,setIsLoading] = useState(inital);
    const spinner = <LoadingIndicator/>
    return{
        isLoading,setIsLoading,spinner
    }
}
export default useLoading;
