import React, {useState,useEffect} from 'react'
import Header from '../../../genericcomponents/Header/GenericHeader';
import useLoading from '../../../genericcomponents/useLoading';
import Style from './SendMailStyle';
import { Typography, makeStyles, Hidden } from '@material-ui/core';
import {baseCalls} from '../../../../apiCalls/baseCalls';
import useSendMail from './useSendMail';
import useTables from '../../../tables/useTables';
import Button from '../../../genericcomponents/Button';
import Table from '../../../tables/SendMailTable';
import {Link as RouterLink} from 'react-router-dom'
const useStyles = makeStyles(() => (Style));
export default function SendMail() {
    const classes = useStyles();
    const {spinner, isLoading, setIsLoading}= useLoading(true)
    const [mailTemplates, setMailTemplates]= useState(null);
    const [templateId,setTemplateId]=useState(null);
    const [noTempErr,setNoTempErr]= useState(false)
    const [isSending, setIsSending]= useState(false);
    const [mailSent, setMailSent] = useState(null);
    const [mailSentResponse,setMailSentResponse]= useState('');
    const [noRecipientError, setNoRecipientError]= useState(false)
    const setId = (e)=> {
        setMailSent(null)
        if(noTempErr===true && e.currentTarget.value!==null){
            setNoTempErr(false)
        }
        setTemplateId(parseInt(e.currentTarget.value));
    }
    const{
        setSupporterTypes,
        setPaymentTypes,
        setStatusTypes,
        supporterTypes,
        paymentTypes,
        statusTypes
    } = useSendMail()
    const {donations,
        setDonations,
        setInitialDonations,
        selectId, updateFilter,
        setStartDateFilter,
        setEndDateFilter,
        setStartActiveFromFilter,
        setEndActiveFromFilter,
        startDateFilter,
        endDateFilter,
        startActiveFromFilter,
        endActiveFromFilter,
        mailRecipientList, 
        controlMailRecipientList,
        mailRef,
        mailSelectedAll,
        selectAllRecipients
    } = useTables()
    useEffect(() => {
        baseCalls.getDonations().then(response=>{
            setDonations(response.data.data[0].f_get_donations)
            setInitialDonations(response.data.data[0].f_get_donations)
            baseCalls.getSupporterTypes().then(response=>{
                setSupporterTypes(response.data.data)
                baseCalls.getPaymentTypes().then(response=>{
                    setPaymentTypes(response.data.data)
                    baseCalls.getStatusTypes().then(response=>{
                        setStatusTypes(response.data.data);
                        baseCalls.getMailTemplates().then(response=>{
                            setMailTemplates(response.data.data)
                            setIsLoading(false)
                        }).catch(err=>{
                              setIsLoading(false)
                              console.log(err)
                        })
                    }).catch(err=>{
                        console.log(err)
                        setIsLoading(false)
                    })
                }).catch(
                    err=>{
                        console.log(err)
                        setIsLoading(false)
                    }
                )
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
            })
        }).catch(err=>{
            console.log(err)
            setIsLoading(false)
        })
        // eslint-disable-next-line
    }, [])
    const sendMail= ()=>{ 
        setNoRecipientError(false)
        setMailSent(null)
        setNoTempErr(false);
        if(templateId===null){
            setNoTempErr(true)
        }
        else if(mailRef.current.length===0){
            setNoRecipientError(true)
        }
        else{
            setIsSending(true)
            baseCalls.sendMail(mailRecipientList,templateId).then(response=>{
                    setMailSent(response.data.success)
                    setIsSending(false)
                    setMailSentResponse("Mailovi poslani!")
                    console.log(templateId)
                    console.log(response)
                
            }).catch(err=>{
                setMailSent(false)
                setMailSentResponse("Greška prilikom slanja!")
                setIsSending(false)
                console.log(err)
            })
        }
        
    }

    return (
        <>
            <Header text={"Slanje maila i obavijesti"} active={"Slanje maila"}/>
            {isLoading ? 
                (spinner)
            :(
                <>
                    <Hidden smDown>
                        <main>
                        <section className={classes.dropdownSection}>
                            <div className={classes.dropdownContainer}>
                                <Typography  className={classes.dropdownLabel}>
                                    Tip donatora
                                </Typography>
                                <select name="supportertype" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        supporterTypes.map(type=>(
                                            <option key= {type.supporters.id} value={type.supporters.id} className={classes.optionStyle}> {type.supporters.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={classes.dropdownContainer}>
                                <Typography className={classes.dropdownLabel}>
                                    Način plaćanja
                                </Typography>
                                <select name="paymenttype" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        paymentTypes.map(type=>(
                                        <option key={type.payment.id} value={type.payment.id} className={classes.optionStyle}>{type.payment.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={classes.dropdownContainer}>
                                <Typography className={classes.dropdownLabel}>
                                    Status
                                </Typography>
                                <select name="status" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        statusTypes.map(type=>(
                                        <option key={type.status.id} value={type.status.id} className={classes.optionStyle}>{type.status.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </section>
                        <div className={classes.dropdownContainer}>
                                <Typography className={classes.dropdownLabel}>
                                    Tip predloška
                                </Typography>
                                <select onChange={setId} className={classes.selectStyle}>
                                    <option value={'null'}>-</option> 
                                    {mailTemplates.map(template=>(
                                            <option key={template.templates.id} value={template.templates.id} className={classes.optionStyle}>{template.templates.name}</option>
                                            ))} 
                                </select>
                            </div>
                        <section className={classes.mailControlSection}>
                            {
                                isSending ? (<span className={classes.spinnerSpan}>{spinner}</span>):(<Button type={"button"} text={"Pošalji mail"} action={sendMail}/>)
                            }
                            <Button component={RouterLink} to={`${process.env.PUBLIC_URL}/app/mailHistory`} text={'Pregled mailova'}/>
                            {
                                noTempErr ?(<Typography className={classes.errorMsg}>Odaberite predložak prije slanja!</Typography> )
                                :
                                (<></>)
                            }
                            {
                                mailSent!==null  ? 
                                    mailSent==true ? 
                                    (<Typography className={classes.updateSuccess}>{mailSentResponse}</Typography>)
                                    :(<Typography className={classes.errorMsg}>{mailSentResponse}</Typography>)
                                : (<></>)
                            }
                            {noRecipientError ?
                            (<Typography className={classes.errorMsg}>Odaberite minimalno jednog primatelja!</Typography>)
                            :(<></>)
                            }
                        </section>
                                          
                        <section className={classes.tableSection}>
                            <Table 
                            donations={donations} 
                            selectId={selectId} 
                            updateFilter={updateFilter}
                            setStartDateFilter={setStartDateFilter}
                            setEndDateFilter={setEndDateFilter}
                            setStartActiveFromFilter={setStartActiveFromFilter}
                            setEndActiveFromFilter={setEndActiveFromFilter}
                            startDateFilter={startDateFilter}
                            endDateFilter={endDateFilter}
                            startActiveFromFilter={startActiveFromFilter}
                            endActiveFromFilter={endActiveFromFilter}
                            controlMailRecipientList={controlMailRecipientList}
                            mailRecipientList={mailRef.current}
                            mailSelectedAll={mailSelectedAll}
                            selectAllRecipients={selectAllRecipients}
                            />
                        </section>
                        </main>
                    </Hidden> 
                    <Hidden mdUp>
                            <main>
                            <section className={classes.dropdownSectionSm}>
                            <div className={classes.dropdownContainerSm}>
                                <Typography  className={classes.dropdownLabelSm}>
                                    Tip donatora
                                </Typography>
                                <select name="supportertype" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        supporterTypes.map(type=>(
                                            <option key= {type.supporters.id} value={type.supporters.id} className={classes.optionStyle}> {type.supporters.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={classes.dropdownContainerSm}>
                                <Typography className={classes.dropdownLabelSm}>
                                    Način plaćanja
                                </Typography>
                                <select name="paymenttype" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        paymentTypes.map(type=>(
                                        <option key={type.payment.id} value={type.payment.id} className={classes.optionStyle}>{type.payment.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={classes.dropdownContainerSm}>
                                <Typography className={classes.dropdownLabelSm}>
                                    Status
                                </Typography>
                                <select name="status" onChange={updateFilter} className={classes.selectStyle}>
                                    <option value={"all"} className={classes.optionStyle}>Svi</option>
                                    {
                                        statusTypes.map(type=>(
                                        <option key={type.status.id} value={type.status.id} className={classes.optionStyle}>{type.status.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </section>
                            <div className={classes.dropdownContainerSm}>
                                <Typography className={classes.dropdownLabel}>
                                    Tip predloška
                                </Typography>
                                <select onChange={setId} className={classes.selectStyle}>
                                    <option value={'null'}>-</option> 
                                    {mailTemplates.map(template=>(
                                            <option key={template.templates.id} value={template.templates.id} className={classes.optionStyle}>{template.templates.name}</option>
                                            ))} 
                                </select>
                            </div>
                            <section className={classes.mailControlSection}>
                            {
                                isSending ? (<span className={classes.spinnerSpan}>{spinner}</span>):(<Button type={"button"} text={"Pošalji mail"} action={sendMail}/>)
                            }
                            <Button component={RouterLink} to={`${process.env.PUBLIC_URL}/app/mailHistory`} text={'Pregled mailova'}/>
                            {
                                noTempErr ?(<Typography className={classes.errorMsg}>Odaberite predložak prije slanja</Typography> )
                                :
                                (<></>)
                            }
                            {
                                mailSent!==null  ? 
                                    mailSent==true ? 
                                    (<Typography className={classes.updateSuccess}>{mailSentResponse}</Typography>)
                                    :(<Typography className={classes.errorMsg}>{mailSentResponse}</Typography>)
                                : (<></>)
                            }
                            </section>
                        <section className={classes.tableSection}>
                            <Table 
                            donations={donations} 
                            selectId={selectId} 
                            updateFilter={updateFilter}
                            setStartDateFilter={setStartDateFilter}
                            setEndDateFilter={setEndDateFilter}
                            setStartActiveFromFilter={setStartActiveFromFilter}
                            setEndActiveFromFilter={setEndActiveFromFilter}
                            startDateFilter={startDateFilter}
                            endDateFilter={endDateFilter}
                            startActiveFromFilter={startActiveFromFilter}
                            endActiveFromFilter={endActiveFromFilter}
                            controlMailRecipientList={controlMailRecipientList}
                            mailRecipientList={mailRef.current}
                            mailSelectedAll={mailSelectedAll}
                            selectAllRecipients={selectAllRecipients}
                            />
                        </section>  
                            </main>
                    </Hidden>
                </>
            )}
        </>
    )
}
