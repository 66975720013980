import {useState} from 'react';

const useMailHistory = ()=>{

    const [supporterTypes,setSupporterTypes] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [statusTypes, setStatusTypes]= useState(null);
    const [mailTemplates, setMailTemplates]= useState(null);

    return{
        setSupporterTypes,
        setPaymentTypes,
        setStatusTypes,
        supporterTypes,
        paymentTypes,
        statusTypes,
        mailTemplates,
        setMailTemplates
        
    }
}

export default useMailHistory