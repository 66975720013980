const DonatorsListStyle={
    grid:{
        backgroundSize: 'cover'
    },
    layout:{
        display: 'grid',
        gridTemplateColumns: '15% 85%',
        padding: 20
    },
    generalHeading:{
        color:'#CEA769',
        margin: 10,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    sideNav:{
        display:'grid',
        placeItems: '',
        height: '10vh'
    },
    buttonLink:{
        backgroundColor:'#CEA769',
        color: 'white',
        marginTop: 10,
        marginBottom: 20,
        fontWeight: 'bold'
    },
    sideNavLink:{
        color: 'white',
        border:'2px solid white',
        borderRadius: '15px',
        textTransform: 'uppercase',
        width: '85%',
        padding: 20,
        marginTop: 10,
        textAlign:'center',
        backgroundColor:'none',
        fontSize: '1rem',
        fontWeight: 'bold',
        '&:hover':{
            color:'#CEA769',
            border:'2px solid #CEA769',
        },
    },
    errorMsg:{
        color: '#cc0000',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif'
    },
    headerLink:{
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    select:{
        color: 'black',
        width:'100%'
    }
    
}

export default DonatorsListStyle