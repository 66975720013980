const Style={
    form:{
        width:'55%',
        display:'grid',
        gridTemplateColumns:'0.7fr 0.3fr',
        margin: '20px 0 0 30px',
    },
    box:{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        border: '1px solid #CEA769',
    },
    label:{
        color:' #CEA769',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textAlign:'left',
        borderRight: '1px solid #CEA769',
        padding: 10,
    },
    formContainer:{
        backgroundColor: '#00172B',
        margin: 10
    },
    buttonContainer:{
        margin: 15,
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'start'
    },
    formFields:{
        margin: 10,
        fontSize:'1rem'
    },
    updateSuccess:{
        color: '#7EBCEA',
    }
}
export default Style;