import axiosConfig from './axiosConfig'
export const baseCalls = {
    logIn,
    logOut,
    getDonations,
    getSupporterTypes,
    getDonatorData,
    updateDonatorData,
    getPaymentTypes,
    getStatusTypes,
    getDonatorTypes,
    getReport,
    addDonator,
    sendMail,
    getMailTemplates,
    updateMailTemplates,
    getSentMailsList
    
}
async function logIn(username, password) {
    try {
        const response = await axiosConfig({
            method: 'POST',
            url: `${process.env.PUBLIC_URL}/API/logIn`,
            data: {
                username,
                password
            }
        });
        return response
    }
    catch (err) {
        console.log(err.response)
        return(err);
    }
}
async function logOut() {
    try {
        await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/logOut`,
        });
        return ({ loggedOut: true })
    }
    catch (err) {
        console.log(err);
    }
}
async function getDonations (){
    try{
        const response = await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/getDonations`,
            params:{
            }
        });
        return response
    }
    catch (err){
        console.log(err)
    }
}
async function getDonatorData (donationId){
    try {
     const response = await axiosConfig({
         method: 'get',
         url: `${process.env.PUBLIC_URL}/API/getSingleDonation`,
         params:{
            donationId
         }
     });
     return response   
    } catch (err) {
        console.log(err)
    }
}
async function getSupporterTypes(){
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/getSupporterTypes`
        });
        return response
    } catch (err) {
        console.log(err)
    }
}
async function updateDonatorData (donationData){
    try{
        const response = await axiosConfig({
            method: 'post',
            url: `${process.env.PUBLIC_URL}/API/updateDonatorData`,
            data:{
                donationData
            }
        });
        return response
    }
    catch (err){
        console.log(err)
    }
}

async function getPaymentTypes(){
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/getPaymentTypes`
        });
        return response
    } catch (err) {
        console.log(err)
    }
}
async function getStatusTypes(){
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/getStatusTypes`
        });
        return response
    } catch (err) {
        console.log(err)
    }
}
async function getReport(excelData){
    try{
        const response= await axiosConfig({
            method: "post",
            url: `${process.env.PUBLIC_URL}/API/exports/excel`,
            responseType: 'arraybuffer',
            data:{
                excelData
            }
        })
        return response
    }catch(err){

    }
}
async function addDonator(donationData){
    try{
        const response =await axiosConfig({
            method: "post",
            url: `${process.env.PUBLIC_URL}/API/addDonator`,
            data:{
                donationData
            }
        })
        return response
    }catch(err){
        console.log(err)
    }
}
async function getDonatorTypes(){
    try {
        const response= await axiosConfig({
            method:'get',
            url: `${process.env.PUBLIC_URL}/API/getDonationTypes`
        })
        return response
    } catch (error) {
        console.log(error)
    }
}
async function getMailTemplates(){
    try {
        const response= await axiosConfig({
            method:'get',
            url: `${process.env.PUBLIC_URL}/API/getMailTemplates`
        })
        return response
    } catch (error) {
        console.log(error)
    }
}
async function updateMailTemplates(id,subject,body){
    try {
        const response= await axiosConfig({
            method:'post',
            url: `${process.env.PUBLIC_URL}/API/updateMailTemplate`,
            data:{
                id,subject,body
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}
async function sendMail(recipients,templateId){
    try{
        const response =await axiosConfig({
            method: 'post',
            url: `${process.env.PUBLIC_URL}/API/mailing/sendMail`,
            data:{
                recipients,templateId
            }
        })
        return response
    }
    catch (error){
        console.log(error)
    }
}
async function getSentMailsList(){
    try{
        const response =await axiosConfig({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/API/getSentMailList`,
        })
        return response
    }
    catch (error){
        console.log(error)
    }
}