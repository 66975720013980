const Style={
    container:{
        position:'absolute',
        bottom: 0,
        display: 'flex',
        justifyContent:'space-between',
        zIndex:10,
        width:'100%',
    },
    imgDekod:{
        maxHeight:'6vh',
        alignSelf:'baseline',
        margin: 10
    },
    imgZaklada:{
        maxHeight:'12vh',
        alignSelf:'baseline',
        margin: 10
    }
}
export default Style;