const Style={
    button:{
        color:'white',
        borderColor: '#CEA769',
        margin: 10,
        backgroundColor:'#CEA769',
        height:'40px',
        width:'210px',
        '&:hover':{
            backgroundColor:'#B7935D',
        },
        fontWeight: 'bold',
    }
}
export default Style