import React from 'react'
import useEditForm from '../../forms/useEditForm';
import { baseCalls } from '../../../apiCalls/baseCalls';
import { Box, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../genericcomponents/Button'
import Style from '../../../styles/FormStyle'
import {useHistory} from 'react-router-dom'
import useLoading from '../../genericcomponents/useLoading'
import LoadingIndicator from '../../genericcomponents/LoadingIndicator';
const useStyles = makeStyles(() => (Style));
export default function EditDonatorDataForm(props) {
    const history= useHistory()
    const classes = useStyles();
    const {donatorData,supporterTypes} = props;
    const {isLoading, setIsLoading} = useLoading(false)
    
    const submit=(e)=>{
        e.preventDefault()
        setDataUpdated(null)
        setIsLoading(true)
            baseCalls.updateDonatorData(data).then(response=>{
                setIsLoading(false)
                setDataUpdated(response.data.success)
                setFormResponseText(response.data.error)
                if(response.data.success===true){
                    setFormResponseText('Podaci ažurirani!')
                }
                
            }).catch(err=>{
                console.log(err)
                setIsLoading(false)
                setDataUpdated(false)
                setFormResponseText('Greška u pozivu!')
            })   
    }
    const neededFields= ['businessname','firstname','lastname','email','address','addressnumber','city','oib']
    const {handleChange,data,
        getNeededFields, labelMapper,
        dataUpdated,setDataUpdated,
        formResponseText,setFormResponseText,
    maxLengthMapper, typeMapper, requiredMapper}= useEditForm(submit,donatorData,neededFields)
    const orderedFields= getNeededFields()
    
    return (
        <>
        <Hidden smDown>
            <form className={classes.form} onSubmit={submit}>
                <Box className={classes.formContainer}>
                    <Box className={classes.box}> 
                        <Typography className={classes.label}>Tip</Typography>
                        <select name={"supportertype"} defaultValue={donatorData.supportertype} onChange={handleChange} className={classes.formFields}>
                            {
                                supporterTypes.map(type=>(
                                    <option key={type.supporters.id} value={type.supporters.id}>{type.supporters.name}</option>)
                                )
                            }
                        </select>
                    </Box>
                    {
                        Object.keys(orderedFields).map(key=>
                            {       let label= labelMapper(key)
                                    let maxLength= maxLengthMapper(key)
                                    let type=typeMapper(key)
                                    let required= requiredMapper(key)
                                    return( 
                                        <Box key={key} className={classes.box}>
                                            <Typography className={classes.label}>{label}</Typography>
                                            <input required={required} maxLength={maxLength} className={classes.formFields} type={type} name={key} key={key} value={data[key] || ""} onChange={handleChange}/>
                                        </Box>
                                    
                                    )

                            }
                        )
                    }
                </Box>
                <Box className={classes.buttonContainer}>
                    <Button action={history.goBack} text={'Povratak'}/>
                    {
                        isLoading ?
                        (<LoadingIndicator/>):
                        (<Button type={"submit"} text={'Spremi promjene'}/>)
                    }
                    {
                        dataUpdated!==null ? 
                            dataUpdated===true ?
                            (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>):
                            (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                        :(<></>)
                    }
                    
                </Box>
            </form>
        </Hidden>
        <Hidden mdUp>
        <form className={classes.formSm}  onSubmit={submit}>
                <Box className={classes.formContainer}>
                    <Box className={classes.box}> 
                        <Typography className={classes.label}>Tip</Typography>
                        <select name={"supportertype"} defaultValue={donatorData.supportertype} onChange={handleChange} className={classes.formFields}>
                            {
                                supporterTypes.map(type=>(
                                    <option key={type.supporters.id} value={type.supporters.id}>{type.supporters.name}</option>)
                                )
                            }
                        </select>
                    </Box>
                    {
                        Object.keys(orderedFields).map(key=>
                            {       let label= labelMapper(key)
                                    let maxLength= parseInt(maxLengthMapper(key))
                                    let type=typeMapper(key)
                                    let required= requiredMapper(key)
                                    return( 
                                        <Box key={key} className={classes.box}>
                                            <Typography className={classes.label}>{label}</Typography>
                                            <input required={required} maxLength={maxLength} className={classes.formFields} type={type} name={key} key={key} value={data[key] || ""} onChange={handleChange}/>
                                        </Box>
                                    
                                    )

                            }
                        )
                    }
                </Box>
                <Box className={classes.buttonContainer}>
                    {
                        isLoading ?
                        (<LoadingIndicator/>):
                        (<Button type={"submit"} text={'Spremi promjene'}/>)
                    }
                    {
                        dataUpdated!==null ? 
                            dataUpdated===true ?
                            (<Typography className={classes.updateSuccess} align={"center"}> {formResponseText}</Typography>):
                            (<Typography color={'error'} align={"center"}> {formResponseText}</Typography>)
                        :(<></>)
                    }
                    <Button action={history.goBack} text={'Povratak'}/>
                </Box>
            </form>
        </Hidden>
        </>
    )
}
