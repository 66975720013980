import React from 'react'
import { useTable,useSortBy,useFilters,usePagination } from 'react-table'
import { makeStyles } from '@material-ui/core/styles';
import TableStyles from './TableStyles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
const useStyles = makeStyles(() => (TableStyles));
 export default function Tables(props) {
    
    const classes = useStyles()
    const {donations,selectId, updateFilter} = props;
    const data = React.useMemo(
     () => donations,
     [donations]
   )
       
   const amountFilterOptions=[
      {text:'Svi',
        value:'all'},
      {
      text:'0-99',
      value: 1
      },
      {
      text:'100-499',
      value: 2
      },
      {
      text:'500-999',
      value: 3
      },
      {
      text:'1000+',
      value: 4
      },
    
    ]
   // Define a default UI for filtering
    const DefaultColumnFilter=({
    column: { filterValue, setFilter,inputClass },
  }) =>{
    return (
      <input
        className={classes[inputClass]}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    )
  }
    const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
    const columns = React.useMemo(
     () => [
       {
        Header: 'Izbor',
        disableFilters: true,
        Cell: props=> {return <input type="radio" name="editId" value={props.row.original.id} onChange={e=>selectId(e.target.value)}/>}
       },
       {
         Header: 'RBR',
         accessor: 'rbr',
         inputClass:'rbr',
       },
       {
        Header: 'Tip',
        accessor: 'supportertypename',
        disableFilters: true
      },{
        Header: 'Naziv',
        accessor: 'businessname',
      },
      {
        Header:'Datum prijenosa/unosa donacije',
        accessor: 'createdat',
        disableFilters: true,
        Cell: props =>  moment(props.row.original.createdat).format("DD.MM.YYYY.")
      },
      {
        Header: 'Datum uplate',
        accessor: 'paymentdat',
        disableFilters: true,
        Cell: props =>{
          if(props.row.original.paymentdat!==null && props.row.original.paymentdat !== "0001-01-01T01:00:00 BC"	){
            return(moment(props.row.original.paymentdat).format("DD.MM.YYYY."))
          }else{
            return(<></>)
          }
         
        } 
      },
      {
        Header:'Aktivan od',
        disableFilters: true,
        accessor: 'active_from',
        Cell: props =>{
          if(props.row.original.active_from!==null){
            return(moment(props.row.original.active_from).format("DD.MM.YYYY."))
          }else{
            return(<></>)
          }
         
        } 
      },
      {
        Header: 'Ime',
        accessor: 'firstname',
        inputClass: 'firstName'
      },
      {
        Header: 'Prezime',
        accessor: 'lastname',
        inputClass: 'lastName'
      },{
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Adresa',
        accessor: 'address',
        inputClass: 'adress'
      },
      {
        Header: 'KBR',
        accessor: 'addressnumber',
        inputClass: 'kbr'
      },
      {
        Header: 'Grad',
        accessor:'city',
        inputClass:'oib'
      },
      {
        Header: 'OIB',
        accessor: 'oib',
        inputClass: 'oib'
      },
      {
        Header: 'Plaćanje',
        accessor: 'paymenttypename',
        inputClass: 'oib'
      },
      {
        Header: 'Status',
        accessor: 'statusname',
        inputClass: 'oib',
      },
      {
        Header: 'Iznos',
        accessor: 'amount',
        inputClass: 'oib',
        amountColumn:true,
        disableFilters: true,
        Cell: props=>{
          let parsed= new Intl.NumberFormat('hr-HR', {style:'currency', currency:'EUR'}).format(props.row.original.amount)
          return(parsed)
        } 
      },
     ],
     []
   )
   // eslint-disable-next-line react-hooks/exhaustive-deps
    
    const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     prepareRow,
     page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
   } = useTable({ columns, data, defaultColumn, initialState:{pageIndex:0} }, useFilters, useSortBy,usePagination)
 
   return (
     <>
    <div className={classes.pagination}>
      <button className={classes.paginationBtn} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <FirstPageIcon fontSize={"small"}/>
      </button>{' '}
      <button className={classes.paginationBtn} onClick={() => previousPage()} disabled={!canPreviousPage}>
        <NavigateBeforeIcon fontSize={"small"}/>
      </button>{' '}
      <button className={classes.paginationBtn} onClick={() => nextPage()} disabled={!canNextPage}>
        <NavigateNextIcon fontSize={"small"}/>
      </button>{' '}
      <button className={classes.paginationBtn} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <LastPageIcon fontSize={"small"}/>
      </button>{' '}
      <span className={classes.indexSpan}>
        Stranica{' '}
        <strong>
          {pageIndex + 1} od {pageOptions.length}
        </strong>{' '}
      </span>
      <select
        className={classes.selectStyle}
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
      >
         {[{text:5, amount:5}, {text:10,amount:10}, {text:20, amount:20}, {text:"sve donacije", amount:donations.length}].map(pageSize => (
        <option key={pageSize.amount} value={pageSize.amount}>
          Prikaži {pageSize.text}
        </option>
      ))}
      </select>
    </div>
     <TableContainer component={Paper} className="administration-table">
     <Table {...getTableProps()} className={classes.table}>
       <TableHead>
         {headerGroups.map(headerGroup => (
           <TableRow {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <TableCell align={'center'} 
                 {...column.getHeaderProps(column.getSortByToggleProps())}
               className={`${classes.heading} administration-cell`}>
                 <div className={classes.thDiv}>
                 {column.render('Header')}
                 <span className={classes.filters}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? (<ArrowDropUpIcon/>)
                        : (<ArrowDropDownIcon/>)
                      : ''}
                  </span>
                  </div>
               </TableCell>
               
             ))}
           </TableRow>
         ))}
         {headerGroups.map(headerGroup => (
           <TableRow {...headerGroup.getHeaderGroupProps()}>
           {headerGroup.headers.map(column => (
          <TableCell key={column.Header} align={"center"} className={classes.heading}>{column.canFilter ? column.render('Filter') : null}
          {column.amountColumn? column.render(
          <select name="amount" onChange={updateFilter} className={classes.selectStyleSm}>
            {
              amountFilterOptions.map(option=>(
              <option key={option.value} value={option.value}>{option.text}</option>
              ))
            }
          </select>
          ):null}
          </TableCell>
             
           ))}
         </TableRow>
         ))}
       </TableHead>
       <TableBody {...getTableBodyProps()}>
         {page.map(row => {
           prepareRow(row)
           return (
             <TableRow   {...row.getRowProps()}>
               {row.cells.map(cell => {
                 let activeClass= classes.cell
                 if(cell.value==='Aktivan'){
                   activeClass=classes.cellActive
                 }
                 return (
                   <TableCell align={"center"}  className={activeClass}
                     {...cell.getCellProps()}>
                     {cell.render('Cell')}
                   </TableCell >
                 )
               })}
             </TableRow >
           )
         })}
       </TableBody>
     </Table>
     </TableContainer>
   </>
   )
 }