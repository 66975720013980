import { SHOW_DRAWER, HIDE_DRAWER } from '../actions/actionTypes';

const initalState = {
    showDrawer: false,
}
export default function visualsReducer(state = initalState, action) {
    switch (action.type) {
        case SHOW_DRAWER:
            return {
                ...state,
                showDrawer: true
            }
        case HIDE_DRAWER:
            return {
                ...state,
                showDrawer: false
            }
        default:
            return state
    }
}