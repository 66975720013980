
import React from "react";
import ReactDOM from "react-dom";
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App'
import './styles.css';
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router >
                <App />
            </Router>
        </PersistGate>
    </Provider>
    , document.getElementById("root"));