const Style={
    menuButton:{
        '&:hover':{
            color:'#CEA769',
        }
    },
    header:{
        backgroundColor:'#00172B'
    },
    title: {
        flexGrow: 1,
        textDecoration: "none"
    },
    list: {
        margin: 20
    },
    paper:{
        background:'#00172B',
        color:'white',
        width:'250px'
    },
    iconStyle:{
        color:'#B7935D'
    },
    link:{
        color:'white',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
        '&:hover':{
            color:'#CEA769',
        }
    },
    divider:{
        background:'black',
        height:'2px'
    },
    active:{
        color:'#CEA769'
    }
}
export default Style