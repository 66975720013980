import {useState} from 'react';

const useAdministration = ()=>{

    const [supporterTypes,setSupporterTypes] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null)

    return{
        setSupporterTypes,
        setPaymentTypes,
        supporterTypes,
        paymentTypes
    }
}

export default useAdministration