import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Style from '../../styles/Buttonstyle'
const useStyles = makeStyles(() => (Style));
export default function GenericButton(props) {
    const classes = useStyles();
    const {action,text, component, to,type}= props
    return (
        <Button type={type} component={component} to={to} className={classes.button} variant="outlined" onClick={action} size="large">
            {text}
        </Button>
    )
}
